import { ReactNode, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Spinner from '../Spinner';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useCurrentUser } from '../../providers/CurrentUserContextProvider';

export interface ProtectedRouteProps {
  children: ReactNode;
}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const {
    isLoading: isAuthLoading,
    isAuthenticated,
    user: authUser,
    loginWithRedirect,
    getAccessTokenSilently,
    error,
    logout,
  } = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();

  const { currentUserData, isUserLoading } = useCurrentUser();

  useEffect(() => {
    if (!isUserLoading && !isAuthLoading) {
      const currentUser = currentUserData?.userByEmail;
      const notLoggedIn = !authUser && !currentUser && !isAuthenticated;
      const loggedIn = authUser && currentUser && isAuthenticated;

      if (error) {
        const loginWithPrompt = () =>
          loginWithRedirect({
            authorizationParams: {
              prompt: 'login',
            },
          });
        const nonCaxyEmailError = 'Access denied. User must have "@caxy.com" email domain.';
        if (nonCaxyEmailError === error.message) {
          toast.dismiss();
          toast.error(
            'We could not find an account associated with the provided login details. \n\n Please check your credentials and try logging in again. \n\nIf you believe this is an error, please contact Operations.',
            {
              duration: 4000,
            }
          );
          setTimeout(() => {
            loginWithPrompt();
          }, 4000);
        } else if (loggedIn) {
          if (error.message === 'login_required' || error.message === 'missing_refresh_token') {
            const checkAuthentication = async () => {
              try {
                await getAccessTokenSilently();
              } catch {
                logout({
                  logoutParams: {
                    returnTo: `${process.env.REACT_APP_CLIENT_URL}/dashboard`,
                  },
                });
              }
            };
            checkAuthentication();
          }
        } else {
          loginWithPrompt();
        }
        return;
      }
      if (notLoggedIn) {
        localStorage.setItem('wtf.redirect_url', location.pathname);
        loginWithRedirect();
      }
    }
  }, [
    authUser,
    currentUserData,
    error,
    getAccessTokenSilently,
    isAuthLoading,
    isAuthenticated,
    isUserLoading,
    location.pathname,
    loginWithRedirect,
    logout,
    navigate,
  ]);

  if (isAuthLoading || isUserLoading) return <Spinner />;

  if (currentUserData) {
    const loggedIn = authUser && isAuthenticated;
    if (loggedIn) {
      return <>{children}</>;
    }
  }
  return <></>;
};

export default ProtectedRoute;
