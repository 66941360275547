import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { FC } from 'react';
import { useParams, useLocation } from 'react-router-dom';

interface TypeDropdownMenuProps {
  selectedValue: string;
  handleSelectChange: (e: SelectChangeEvent<string>) => void;
  disabled?: boolean;
}

export type RequestType = 'pto' | 'unpaid' | 'exception' | 'wfh' | 'flex';

interface RequestTypeWithText {
  value: RequestType;
  text: string;
}

const requestTypeOptions: RequestTypeWithText[] = [
  {
    value: 'pto',
    text: 'Paid Time Off',
  },
  {
    value: 'unpaid',
    text: 'Unpaid Time Off',
  },
  {
    value: 'exception',
    text: 'Exception Time',
  },
  {
    value: 'wfh',
    text: 'Work From Home',
  },
  {
    value: 'flex',
    text: 'Flex',
  },
];

const getCurrentMenuOption = (requestType: string) => {
  const currentRequestType = requestTypeOptions.find(
    (type: RequestTypeWithText) => type.value === requestType
  );

  //  Used this syntax "current!" because I set the values myself and am 100% sure there will not be an undefined value. Not dependant on any fetched data.
  const { text, value } = currentRequestType!;

  return <MenuItem value={value}>{text}</MenuItem>;
};

const TypeDropdownMenu: FC<TypeDropdownMenuProps> = ({
  selectedValue,
  handleSelectChange,
  disabled,
}) => {
  const { id } = useParams();
  const location = useLocation();

  const isEditPage = location.pathname === `/requests/edit/${id}`;

  return (
    <>
      <InputLabel id='request-type-label' sx={{ fontWeight: 'bold', color: 'black', mb: '-1rem' }}>
        Type
      </InputLabel>
      <FormControl fullWidth>
        <Select
          labelId='request-type-label'
          value={selectedValue}
          onChange={handleSelectChange}
          disabled={disabled}
        >
          {isEditPage
            ? getCurrentMenuOption(selectedValue)
            : requestTypeOptions.map((type, i) => (
                <MenuItem key={`${type.value}-${i}`} value={type.value}>
                  {type.text}
                </MenuItem>
              ))}
        </Select>
      </FormControl>
    </>
  );
};

export default TypeDropdownMenu;
