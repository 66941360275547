import { gql } from '@apollo/client';

export const GET_PTO_SPEND = gql`
  query GetUsersPtoSpend {
    getUsersPtoSpend {
      high {
        username
        ptoHours
        startDate
        email
        id
        ptoHoursAvailable
      }
      ideal {
        username
        ptoHours
        startDate
        email
        id
        ptoHoursAvailable
      }
      low {
        username
        ptoHours
        startDate
        email
        id
        ptoHoursAvailable
      }
    }
  }
`;
