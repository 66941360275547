import { useAuth0 } from '@auth0/auth0-react';
import { ReactNode, useState, createContext, useContext, useEffect, useCallback } from 'react';
import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_AUTH_ID } from '../graphql/users/mutations/addUserAuth';
import { GET_USER_BY_EMAIL } from '../graphql/users/queries/getUserByEmail';
import { Role } from '../enums/role';
import { useNavigate } from 'react-router-dom';

export interface UserByEmail {
  id: string;
  username: string;
  email: string;
  role: Role;
  startDate: string;
  ptoRate: number;
  flexDays: number;
  wfhDays: number;
  exceptionDays: number;
  emailSubscriptions: string;
  ptoHoursRemaining: number;
  ptoHours: number;
  unpaidHours: number;
  displayName: string;
  auth0Id: string | null;
}

export interface User {
  userByEmail: UserByEmail;
}

export interface CurrentUserContextType {
  currentUserData: User | null | undefined;
  setCurrentUserData: React.Dispatch<React.SetStateAction<User | null>>;
  isUserLoading: boolean;
  isAdmin: boolean;
  authToken: string | null;
  setAuthToken: React.Dispatch<React.SetStateAction<string | null>>;
}

export const useCurrentUser = () => {
  return useContext(CurrentUserContext);
};

export const CurrentUserContext = createContext<CurrentUserContextType>({
  currentUserData: null,
  setCurrentUserData: () => {},
  isUserLoading: false,
  isAdmin: false,
  authToken: null,
  setAuthToken: () => null,
});

const CurrentUserProvider = ({ children }: { children: ReactNode }) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [currentUserData, setCurrentUserData] = useState<User | null>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [authToken, setAuthToken] = useState<string | null>(null);
  const navigate = useNavigate();

  const { data, loading: isUserLoading } = useQuery(GET_USER_BY_EMAIL, {
    variables: {
      email: user?.email,
    },
    skip: !user?.email,
    onCompleted: async (data) => {
      // If user doesn't have their auth0Id yet, once they log in for the first time, it should set their auth0Id to the id from auth0 object
      if (!data.userByEmail.auth0Id) {
        await addUserAuth({
          variables: {
            input: {
              email: user?.email,
              auth0Id: user?.sub,
            },
          },
        });
      }

      if (data.userByEmail?.role === Role.ADMIN) {
        setIsAdmin(true);
      }
    },
    onError: async (error) => {
      // for any network errors, navigate to '/500' error page
      if (error.networkError) {
        navigate('/500');
        return;
      }
    },
  });
  const getAccessToken = useCallback(async () => {
    const token = await getAccessTokenSilently();
    setAuthToken(token);
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (user) {
      getAccessToken();
    }
  }, [getAccessToken, getAccessTokenSilently, user]);

  useEffect(() => {
    if (data) {
      setCurrentUserData(data);
    }
  }, [data]);

  const [addUserAuth] = useMutation(ADD_AUTH_ID);

  return (
    <CurrentUserContext.Provider
      value={{
        currentUserData,
        setCurrentUserData,
        isUserLoading,
        isAdmin,
        authToken,
        setAuthToken,
      }}
    >
      {' '}
      {children}
    </CurrentUserContext.Provider>
  );
};

export default CurrentUserProvider;
