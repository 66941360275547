import { MouseEvent, useState } from 'react';
import { ClickAwayListener, Menu } from '@mui/material';
import { MenuItem } from '../navigation/MenuItem';

interface RequestDropdownProps {
  navClose: () => void;
}

const RequestDropdown = ({ navClose }: RequestDropdownProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavClose = () => {
    handleClose();
    navClose();
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <>
        <MenuItem width='auto' padding={{ xs: '.38rem 1rem', md: 0 }} onClick={handleClick}>
          Request
        </MenuItem>
        <Menu
          sx={{ ml: { xs: '-15px', md: 0 } }}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
        >
          <MenuItem onClick={handleNavClose} url='requests/pto' linkText='Request Time Off' />
          <MenuItem
            onClick={handleNavClose}
            url='/requests/exception'
            linkText='Request Exception Time'
          />
          <MenuItem
            onClick={handleNavClose}
            url='/requests/wfh'
            linkText='Request Work From Home'
          />
          <MenuItem onClick={handleNavClose} url='/requests/flex' linkText='Request Flex' />
        </Menu>
      </>
    </ClickAwayListener>
  );
};

export default RequestDropdown;
