import { useQuery } from '@apollo/client';
import { Box, Tab, Tabs, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import styled from 'styled-components';
import TabTable from '../../../components/Tables/TabTable';
import { GET_ALL_DATE_EVENTS_BY_ID } from '../../../graphql/dateEvents/queries/getDateEventsByUser';
import { useCurrentUser } from '../../../providers/CurrentUserContextProvider';
import Header from '../../../components/text/Header';
import TimeUntilResets, { PTOHour } from '../../../components/userStats/TimeUntilResets';
import { formatNumberWithDecimal } from '../../../helpers/formattingAccruedBalance';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const StyledTab = styled(Tab)({
  fontSize: '1rem',
  padding: '0.5rem',
  minWidth: 'auto',
});

const EventHistory: FC = () => {
  const { currentUserData } = useCurrentUser();
  const user = currentUserData?.userByEmail;
  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const theme = useTheme();
  const { caxyGreen } = theme.colors;

  const { data: dateEvents } = useQuery(GET_ALL_DATE_EVENTS_BY_ID, {
    variables: {
      userId: user?.id,
    },
  });

  const sortedEvents = [...(dateEvents?.dateEventByUserId || [])].sort(
    (a, b) => new Date(b.start).getTime() - new Date(a.start).getTime()
  );

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
          gap: 1,
          marginX: '1rem',
          width: '44rem',
          '@media (min-width: 20rem)': {
            width: '19rem',
          },
          '@media (min-width: 22.5rem)': {
            width: '21rem',
          },
          '@media (min-width: 25.063rem)': {
            width: '24rem',
          },
          '@media (min-width: 32.875rem)': {
            width: '28rem',
          },
          '@media (min-width: 37.5rem)': {
            width: '34rem',
          },
          '@media (min-width: 56.25rem)': {
            width: '37rem',
          },
          overflow: 'visible',
        }}
      >
        <Header>Your Time Off Requests</Header>
        <TimeUntilResets variant='compact' />
        <Box sx={{ marginTop: '1rem' }}>
          <Tabs
            value={activeTab}
            onChange={handleChange}
            sx={{
              '& .MuiTabs-indicator': {
                backgroundColor: caxyGreen,
              },
              '& .Mui-selected': {
                color: 'black',
              },
              marginTop: '.5rem',
              backgroundColor: 'white',
              borderRadius: '0.3rem 0.3rem 0 0',
            }}
          >
            <StyledTab label='PTO' />
            <StyledTab label='Flex' />
            <StyledTab label='WFH' />
            <StyledTab label='Exception' />
            <StyledTab label='Unpaid' />
          </Tabs>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              boxShadow: '1rem 1rem 2rem #00000033',
              marginBottom: '1rem',
              borderRadius: '0 0 0.3rem 0.3rem',
              overflow: 'auto',
              backgroundColor: 'white',
            }}
          >
            <CustomTabPanel value={activeTab} index={0}>
              <Box>
                <TabTable data={sortedEvents} type={'pto'} />
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={activeTab} index={1}>
              <Box>
                <TabTable data={sortedEvents} type={'flex'} />
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={activeTab} index={2}>
              <Box>
                <TabTable data={sortedEvents} type={'wfh'} />
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={activeTab} index={3}>
              <Box>
                <TabTable data={sortedEvents} type={'exception'} />
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={activeTab} index={4}>
              <Box>
                <TabTable data={sortedEvents} type={'unpaid'} />
              </Box>
            </CustomTabPanel>
          </Box>
        </Box>
        {user && (
          <PTOHour style={{ paddingTop: '1rem', paddingBottom: '2rem', margin: 0 }}>
            *Note: PTO Hours are front-loaded for your convenience, but do accrue over time. Your
            actual accrued balance is <strong>{formatNumberWithDecimal(user.ptoHours)}</strong>{' '}
            hours
          </PTOHour>
        )}
      </Box>
    </>
  );
};

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export default EventHistory;
