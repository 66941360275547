import { Box } from '@mui/material';
import { SortByInterface } from '../../helpers/sorting';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

interface Props {
  sortConfig: SortByInterface;
}

const SortArrow = ({ sortConfig }: Props) => {
  const { direction } = sortConfig;

  return (
    <Box
      className='sortArrow'
      sx={{
        display: 'inline-flex',
        verticalAlign: 'middle',
        alignItems: 'center',
        marginLeft: '0.25rem',
      }}
    >
      {direction === 'ASC' ? (
        <KeyboardArrowDown fontSize='small' />
      ) : (
        <KeyboardArrowUp fontSize='small' />
      )}
    </Box>
  );
};

export default SortArrow;
