import { gql } from '@apollo/client';

export const GET_PTO_DATA = gql`
  query getPtoData($id: Int!) {
    ptoData(id: $id) {
      totalRemainingHours
      totalHoursUsed
    }
  }
`;
