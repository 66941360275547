import { AppBar, Box, Container, IconButton, Menu, MenuItem, Toolbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { useTheme } from '@mui/material';
import Badge from '@mui/material/Badge';
import React, { FC, useState } from 'react';
import { useCurrentUser } from '../../providers/CurrentUserContextProvider';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import RequestDropdown from '../RequestDropdown/Request';
import LoginLogout from '../LoginLogout';
import { MenuItem as CustomMenuItem } from './MenuItem';
import { LinkTag } from '../buildBreadcrumbs/buildBreadcrumbs';
import { GET_ALL_PENDING_DATE_EVENTS } from '../../graphql/dateEvents/queries/getAllPendingDateEvents';
import ReportsDropdown from '../Dropdowns/ReportsDropdown';

const NavigationBar: FC = () => {
  const theme = useTheme();
  const { caxyNavy, caxyGreen } = theme.colors;
  const { fontFamily } = theme.typography;
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const { isAdmin } = useCurrentUser();
  const { data: dateEvent } = useQuery(GET_ALL_PENDING_DATE_EVENTS);

  const handleOpenNavMenu = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(e.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleClickAway = () => {
    handleCloseNavMenu();
  };

  return (
    <>
      <AppBar sx={{ backgroundColor: caxyNavy }} position='sticky'>
        <Container maxWidth='xl'>
          <Toolbar>
            <Link to='/'>
              <img
                src='/CaxyLogo1.png'
                alt='CaxyLogo'
                style={{
                  height: '1.5rem',
                  objectFit: 'contain',
                  marginRight: '2.5rem',
                  marginBottom: '-0.2rem',
                }}
              />
            </Link>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'flex', md: 'none' },
                justifyContent: 'flex-end',
              }}
            >
              <ClickAwayListener onClickAway={handleClickAway}>
                <Box
                  sx={{
                    flexGrow: 1,
                    display: { xs: 'flex', md: 'none' },
                    justifyContent: 'flex-end',
                  }}
                >
                  <IconButton
                    aria-label='Navigation dropdown menu'
                    onClick={handleOpenNavMenu}
                    sx={{
                      display: { xs: 'flex' },
                      justifyContent: 'center',
                    }}
                  >
                    <MenuIcon
                      sx={{
                        color: caxyGreen,
                        display: { xs: 'flex' },
                        justifyContent: 'center',
                      }}
                    />
                  </IconButton>
                  <Menu
                    anchorEl={anchorElNav}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                  >
                    <CustomMenuItem onClick={handleCloseNavMenu} url='/dashboard' linkText='Home' />
                    <CustomMenuItem
                      onClick={handleCloseNavMenu}
                      url='/profile'
                      linkText='Profile'
                    />
                    {isAdmin && (
                      <CustomMenuItem onClick={handleCloseNavMenu} url='/users' linkText='Users' />
                    )}

                    {isAdmin && <ReportsDropdown navClose={handleCloseNavMenu} />}
                    {isAdmin && (
                      <MenuItem
                        component='a'
                        sx={{ padding: '.38rem 1rem', minHeight: '0' }}
                        onClick={handleCloseNavMenu}
                        href='/admin'
                        style={{
                          display: 'inline-block',
                          textDecoration: 'none',
                          color: '000000DE',
                          width: '100%',
                        }}
                      >
                        Admin
                      </MenuItem>
                    )}
                    {isAdmin && dateEvent !== null && (
                      <CustomMenuItem onClick={handleCloseNavMenu}>
                        {' '}
                        <LinkTag underlineOnHover={false} to='requests/pending'>
                          Pending Events
                          <Badge
                            badgeContent={dateEvent?.dateEvents.length}
                            sx={{
                              pl: '1rem',
                              pb: '.3rem',
                              '& .MuiBadge-badge': {
                                backgroundColor: theme.colors.caxyGreen,
                                color: 'white',
                              },
                              marginLeft: '0.15rem',
                            }}
                          />
                        </LinkTag>
                      </CustomMenuItem>
                    )}
                    <RequestDropdown navClose={handleCloseNavMenu} />
                    <CustomMenuItem>
                      <LoginLogout
                        customStyles={{
                          sx: {
                            width: '100%',
                            height: '100%',
                            color: { xs: '#000000DE', md: 'white' },
                            padding: 0,
                            display: 'flex',
                            justifyContent: 'start',
                            lineHeight: '1.3rem',

                            '&:hover': {
                              backgroundColor: { xs: 'transparent', md: 'inherit' },
                              borderColor: { md: 'inherit' },
                              color: { md: 'inherit' },
                            },
                          },
                        }}
                        isStylesDefault={false}
                      />
                    </CustomMenuItem>
                  </Menu>
                </Box>
              </ClickAwayListener>
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'none', md: 'flex' },
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                }}
              >
                <Box sx={{ marginBottom: '0.1rem' }}>
                  <RequestDropdown navClose={handleCloseNavMenu} />
                </Box>
                <Box
                  sx={{
                    fontFamily,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                  }}
                >
                  {isAdmin && (
                    <Link
                      style={{
                        padding: '0',
                        textDecoration: 'none',
                        color: 'white',
                        marginBottom: '0.1rem',
                      }}
                      to='/users'
                    >
                      Users
                    </Link>
                  )}
                </Box>
                <Box
                  sx={{
                    fontFamily,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                  }}
                >
                  {isAdmin && <ReportsDropdown navClose={handleCloseNavMenu} />}
                </Box>
                <Box
                  sx={{
                    fontFamily,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                  }}
                >
                  {isAdmin && (
                    <MenuItem component='a' sx={{ padding: '0' }} href='/admin'>
                      Admin
                    </MenuItem>
                  )}
                </Box>

                <Box
                  sx={{
                    fontFamily,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                  }}
                >
                  {isAdmin && dateEvent !== null && (
                    <>
                      <Link
                        style={{
                          padding: 0,
                          textDecoration: 'none',
                          color: 'white',
                          marginRight: '0.17rem',
                          marginBottom: '0.1rem',
                        }}
                        to='/requests/pending'
                      >
                        Pending Events
                      </Link>
                      <Link style={{ padding: 0 }} to='/requests/pending'>
                        <Badge
                          badgeContent={dateEvent?.dateEvents.length}
                          sx={{
                            '& .MuiBadge-badge': {
                              backgroundColor: theme.colors.caxyGreen,
                              color: 'white',
                            },
                            marginLeft: '-0.06rem',
                            marginBottom: '0.3rem',
                          }}
                        />
                      </Link>
                    </>
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  fontFamily,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                }}
              >
                <Link style={{ padding: 0, textDecoration: 'none', color: 'white' }} to='/profile'>
                  Profile
                </Link>
                <LoginLogout />
              </Box>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};
export default NavigationBar;
