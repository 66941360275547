import { useQuery } from '@apollo/client';
import { GET_DATE_EVENT_BY_ID } from '../../../graphql/dateEvents/queries/getDateEventById';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import ReusableFormComponent from '../../../components/ReusableForm/ReusableFormComponent';
import Spinner from '../../../components/Spinner';
import { RequestStatus } from '../../../enums/RequestStatus';
import { useCurrentUser } from '../../../providers/CurrentUserContextProvider';
import BaseErrorPage from '../../ErrorPages/BaseErrorPage';

export interface DateEvent {
  id: number;
  start: string;
  end: string;
  reasonRequested: string;
  eventType: {
    name: string;
    code: string;
  };
  compensationType?: string;
  user: {
    id: number;
    ptoHours: number;
    username: string;
  };
  status: RequestStatus;
  statusReason?: string;
}

const EditRequest = () => {
  const { id } = useParams();
  const { data, loading, error } = useQuery(GET_DATE_EVENT_BY_ID, {
    variables: { id: Number(id) },
  });
  const { currentUserData, isAdmin } = useCurrentUser();

  if (error) {
    return <BaseErrorPage error={404} message={`Oops! Not clear how to leap to that page`} />;
  }

  if (!isAdmin && data?.dateEventById.user.id !== currentUserData?.userByEmail.id) {
    return <BaseErrorPage error={403} message={`You're toad-ally not allowed here!`} />;
  }

  const formDisabled = data?.dateEventById.status === RequestStatus.Approved && !isAdmin;

  return loading ? (
    <Spinner />
  ) : (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingY: '2rem',
        paddingX: '1rem',
      }}
    >
      <ReusableFormComponent
        currentPage={data?.dateEventById.eventType.code}
        existingEvent={data?.dateEventById}
        disabled={formDisabled}
      />
    </Box>
  );
};

export default EditRequest;
