import { gql } from '@apollo/client';

export const GET_USER_BY_EMAIL = gql`
  query getUserByEmail($email: String!) {
    userByEmail(email: $email) {
      email
      emailSubscriptions
      flexDays
      exceptionDays
      id
      ptoHours
      ptoRate
      role
      startDate
      username
      wfhDays
      auth0Id
      displayName
      unpaidHours
      ptoHoursRemaining
    }
  }
`;
