import { gql } from '@apollo/client';

export const CREATE_DATE_EVENT = gql`
  mutation createDateEvent($input: CreateDateEventInput!) {
    createDateEvent(createDateEventInput: $input) {
      userId
      eventTypeId
      eventType {
        code
      }
      start
      end
      reasonRequested
      status
      statusReason
      groupId
    }
  }
`;
