import { DateTime } from 'luxon';

export const getDaysUntilAnniversary = (startDateOfUser: string | Date | undefined) => {
  const today = DateTime.now();
  let date;
  if (typeof startDateOfUser === 'string') {
    date = DateTime.fromISO(startDateOfUser);
  } else if (startDateOfUser instanceof Date) {
    date = DateTime.fromJSDate(startDateOfUser);
  }

  const anniversaryThisYear = DateTime.fromObject({
    month: date?.month,
    day: date?.day,
    year: today.year,
  });

  const nextAnniversary =
    anniversaryThisYear < today ? anniversaryThisYear.plus({ years: 1 }) : anniversaryThisYear;
  const daysUntilAnniversary = Math.floor(nextAnniversary.diff(today, 'days').days);

  return { nextAnniversary, daysUntilAnniversary };
};
