import { Box, Stack, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { DateTime } from 'luxon';
import { GET_ALL_USERS } from '../../graphql/users/queries/getAllUsers';
import { Link } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import { GetAllUsers } from '../Admin/AdminUserPage';
import { getDaysUntilAnniversary } from '../../utils/getDaysUntilAnniversary';
import { formatNumberWithDecimal } from '../../helpers/formattingAccruedBalance';
import { StyledTableCell, StyledTableHeader } from '../User/Events/PendingEvents';
import { SortByInterface } from '../../helpers/sorting';
import SortArrow from '../../components/Tables/SortArrow';
import { handleSort } from '../../helpers/handleSort';

export interface TotalBenefitsColumnData {
  id: number;
  user: string;
  ptoHoursUsed: number;
  ptoPercentRemaining: string;
  daysUntilReset: number;
  flexDaysUsed: number;
  wfhDaysUsed: number;
  exceptionsUsed: number;
  unpaidTimeUsed: number;
}

interface ColumnData {
  dataKey: keyof TotalBenefitsColumnData;
  label: string;
  numeric?: boolean;
}

const columns: ColumnData[] = [
  {
    label: 'User',
    dataKey: 'user',
  },
  {
    label: 'PTO Hours Used',
    dataKey: 'ptoHoursUsed',
    numeric: true,
  },
  {
    label: 'PTO % Remaining',
    dataKey: 'ptoPercentRemaining',
  },
  {
    label: 'Days Until Reset',
    dataKey: 'daysUntilReset',
    numeric: true,
  },
  {
    label: 'Flex Days Used',
    dataKey: 'flexDaysUsed',
    numeric: true,
  },
  {
    label: 'WFH Days Used',
    dataKey: 'wfhDaysUsed',
    numeric: true,
  },
  {
    label: 'Exceptions Used',
    dataKey: 'exceptionsUsed',
    numeric: true,
  },
  {
    label: 'Unpaid Time Used',
    dataKey: 'unpaidTimeUsed',
    numeric: true,
  },
];

export const columnDataKeys = columns.map((column) => column.dataKey);

const createData = (
  id: number,
  user: string,
  ptoHoursUsed: number,
  ptoPercentRemaining: string,
  daysUntilReset: number,
  flexDaysUsed: number,
  wfhDaysUsed: number,
  exceptionsUsed: number,
  unpaidTimeUsed: number
): TotalBenefitsColumnData => {
  return {
    id,
    user,
    ptoHoursUsed,
    ptoPercentRemaining,
    flexDaysUsed,
    daysUntilReset,
    wfhDaysUsed,
    exceptionsUsed,
    unpaidTimeUsed,
  };
};

const TotalBenefitsPage = () => {
  const [sortConfig, setSortConfig] = useState<SortByInterface>({ column: null, direction: 'ASC' });

  const { column, direction } = sortConfig;

  const { data, loading, error } = useQuery(GET_ALL_USERS, {
    variables: {
      isPtoHoursUsed: true,
    },
  });

  const manufacturedData: TotalBenefitsColumnData[] = useMemo(() => {
    if (!data) return [];

    return data.users?.map((data: GetAllUsers) => {
      const givenDate = DateTime.fromISO(data.startDate);
      const today = DateTime.now().startOf('day');

      let nextAnniversary = givenDate.set({ year: today.year });
      if (nextAnniversary < today) {
        nextAnniversary = nextAnniversary.plus({ years: 1 });
      }

      const percentRemaining = `${formatNumberWithDecimal(data.percentRemaining)}%`;

      return createData(
        data.id,
        data.username,
        parseFloat(formatNumberWithDecimal(data.ptoHoursUsed)),
        percentRemaining,
        getDaysUntilAnniversary(data.startDate).daysUntilAnniversary,
        24 - data.flexDays,
        24 - data.wfhDays,
        24 - data.exceptionDays,
        parseFloat(formatNumberWithDecimal(data.unpaidHours))
      );
    });
  }, [data]);

  const onSort = useCallback(
    (columnName: string) => {
      const newDirection = handleSort(columnName, sortConfig);
      setSortConfig({ column: columnName, direction: newDirection });
    },
    [sortConfig, setSortConfig]
  );

  const sortedData = useMemo(() => {
    if (!manufacturedData.length || !column) return manufacturedData;

    // We know the sortConfig.column is of the correct type because it is sourced from column.dataKey, but need to keep TypeScript happy since the original shape of sortConfig.column is string
    const sortColumn: keyof TotalBenefitsColumnData = column as keyof TotalBenefitsColumnData;

    return [...manufacturedData].sort((a, b) => {
      const isReversed = direction === 'DESC' ? 1 : -1;

      if (typeof a[sortColumn] === 'string' && typeof b[sortColumn] === 'string') {
        return isReversed * String(a[sortColumn]).localeCompare(String(b[sortColumn]));
      }

      if (typeof a[sortColumn] === 'number' && typeof b[sortColumn] === 'number') {
        return isReversed * (Number(a[sortColumn]) - Number(b[sortColumn]));
      }

      return 0;
    });
  }, [manufacturedData, column, direction]);

  if (loading) return <Spinner />;
  if (error) return <p>Error: {error.message}</p>;

  const tableHeader = () => {
    return (
      <TableRow>
        {columns.map((singleColumn) => (
          <StyledTableHeader
            key={singleColumn.dataKey}
            variant='head'
            align='left'
            sx={{
              width: 'calc(100% / 8)',
            }}
            onClick={() => onSort(singleColumn.dataKey)}
            sortConfig={sortConfig}
            selected={column === singleColumn.dataKey}
          >
            <Stack direction='row'>
              {singleColumn.label}
              <SortArrow sortConfig={sortConfig} />
            </Stack>
          </StyledTableHeader>
        ))}
      </TableRow>
    );
  };

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        marginX: '2rem',
        marginTop: '2rem',
        marginBottom: '2rem',
        boxShadow: '1rem 1rem 2rem #00000033',
        borderRadius: '0.3rem',
        paddingX: '1rem',
        overflowX: 'auto',
        '@media (min-width: 20rem)': {
          width: '16.5rem',
        },
        '@media (min-width: 25rem)': {
          width: '22.5rem',
        },
        '@media (min-width: 34.375rem)': {
          width: '26rem',
        },
        '@media (min-width: 37.5rem)': {
          width: '32rem',
        },
        '@media (min-width: 48.438rem)': {
          width: '40rem',
        },
        '@media (min-width: 56.25rem)': {
          width: '50rem',
        },
        '@media (min-width: 68.75rem)': {
          width: '65rem',
        },
      }}
    >
      <TableContainer>
        <Table stickyHeader>
          <TableHead>{tableHeader()}</TableHead>
          <TableBody>
            {sortedData?.map((row, index) => (
              <TableRow key={index}>
                <StyledTableCell
                  sx={{
                    maxWidth: '7rem',
                    overflowX: 'scroll',
                    '&::-webkit-scrollbar': {
                      display: 'none',
                    },
                    scrollbarWidth: 'none',
                  }}
                >
                  <Link
                    to={`/users/${row.id}/view`}
                    style={{ textDecoration: 'none', color: '#43b458', fontWeight: 'bold' }}
                  >
                    {row.user}
                  </Link>
                </StyledTableCell>
                <StyledTableCell>{row.ptoHoursUsed}</StyledTableCell>
                <StyledTableCell>{row.ptoPercentRemaining}</StyledTableCell>
                <StyledTableCell>{row.daysUntilReset}</StyledTableCell>
                <StyledTableCell>{row.flexDaysUsed}</StyledTableCell>
                <StyledTableCell>{row.wfhDaysUsed}</StyledTableCell>
                <StyledTableCell>{row.exceptionsUsed}</StyledTableCell>
                <StyledTableCell>{row.unpaidTimeUsed}</StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TotalBenefitsPage;
