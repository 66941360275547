import { gql } from '@apollo/client';

export const BULK_CREATE_DATE_EVENT = gql`
  mutation bulkCreateRequest($input: BulkCreateInput!) {
    bulkCreateRequest(bulkCreateInput: $input) {
      userId
      eventTypeId
      eventType {
        code
      }
      start
      end
      reasonRequested
      status
      statusReason
      groupId
    }
  }
`;
