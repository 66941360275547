import { ChangeEvent, FC } from 'react';
import { Box, InputLabel, TextField } from '@mui/material';
import { DateTime } from 'luxon';

interface DateAndTimeProps {
  startDate: DateTime;
  startTime: string;
  handleStartDateChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleStartTimeChange: (e: ChangeEvent<HTMLInputElement>) => void;
  endDate: DateTime;
  endTime: string;
  handleEndDateChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleEndTimeChange: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const DateAndTime: FC<DateAndTimeProps> = ({
  startDate,
  startTime,
  handleStartDateChange,
  handleStartTimeChange,
  endDate,
  endTime,
  handleEndDateChange,
  handleEndTimeChange,
  disabled,
}) => {
  return (
    <>
      <InputLabel
        htmlFor='start-date-input'
        sx={{ fontWeight: 'bold', color: 'black', mb: '-1rem' }}
      >
        Start
      </InputLabel>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 1 }}>
        <TextField
          id='start-date-input'
          required
          type='date'
          value={startDate.toISODate() || ''}
          onChange={handleStartDateChange}
          sx={{ flex: 1, marginRight: { sm: '1.5rem' } }}
          disabled={disabled}
        />
        <TextField
          id='start-date-input'
          type='time'
          value={startTime}
          onChange={handleStartTimeChange}
          sx={{ flex: 1 }}
          disabled={disabled}
          inputProps={{
            step: 1800,
          }}
        />
      </Box>
      <InputLabel htmlFor='end-date-input' sx={{ fontWeight: 'bold', color: 'black', mb: '-1rem' }}>
        End
      </InputLabel>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 1 }}>
        <TextField
          id='end-date-input'
          required
          type='date'
          value={endDate.toISODate() || ''}
          onChange={handleEndDateChange}
          sx={{ flex: 1, marginRight: { sm: '1.5rem' } }}
          disabled={disabled}
        />
        <TextField
          id='end-date-input'
          type='time'
          value={endTime}
          onChange={handleEndTimeChange}
          sx={{ flex: 1 }}
          disabled={disabled}
          inputProps={{
            step: 1800,
          }}
        />
      </Box>
    </>
  );
};

export default DateAndTime;
