import { gql } from '@apollo/client';

export const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(updateUserInput: $input) {
      wfhDays
      username
      startDate
      role
      ptoRate
      ptoHours
      id
      flexDays
      emailSubscriptions
      email
      exceptionDays
      unpaidHours
    }
  }
`;
