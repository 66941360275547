import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Box, ClickAwayListener, Link, styled, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useMutation, useQuery } from '@apollo/client';
import { GET_DATE_EVENT_BY_ID } from '../../graphql/dateEvents/queries/getDateEventById';
import { DateTime } from 'luxon';
import ReusableButton from '../../components/buttons/ReusableButton';
import { useCurrentUser } from '../../providers/CurrentUserContextProvider';
import BuildBreadcrumbs from '../../components/buildBreadcrumbs/buildBreadcrumbs';
import { UPDATE_USER } from '../../graphql/users/mutations/updateUser';
import { RequestStatus } from '../../enums/RequestStatus';
import toast from 'react-hot-toast';
import { REMOVE_DATE_EVENT } from '../../graphql/dateEvents/mutations/deleteDateEvent';
import { BULK_APPROVE_OR_DENY_DATE_EVENT } from '../../graphql/dateEvents/mutations/bulkApproveOrDenyDateEvent';
import { Role } from '../../enums/role';
import { GET_USER_BY_EMAIL } from '../../graphql/users/queries/getUserByEmail';
import DeleteModalComponent from '../../components/modal/DeleteModalComponent';
import ConfirmationModalComponent from '../../components/modal/ConfirmationModalComponent';
import { cache } from '../../providers/AuthorizedApolloProvider';
import Spinner from '../../components/Spinner';
import { useDeleteRequest } from '../../providers/DeleteRequestContextProvider';
import { formatNumberWithDecimal } from '../../helpers/formattingAccruedBalance';
import { APPROVE_OR_DENY_SINGLE_DATE_EVENT } from '../../graphql/dateEvents/mutations/approveOrDenyRequest';
import { BULK_DELETE_EVENTS } from '../../graphql/dateEvents/mutations/bulkDeleteRequests';

const UserInfo = styled(Typography)`
  flex-direction: column;
  padding: 0.5rem;
  align-items: flex-start;
  text-align: left;
`;

export enum RequestAction {
  Approved = 'Approved',
  Denied = 'Denied',
  Delete = 'Delete',
}

const UserRequest = () => {
  const [approved, setApproved] = useState<boolean>(false);
  const [approvedEvent, setApprovedEvent] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [statusReason, setStatusReason] = useState<string>('');
  const [isStatusEmpty, setIsStatusEmpty] = useState<boolean>(false);
  const [deny, setDeny] = useState<boolean>(false);
  const [denyEvent, setDenyEvent] = useState<boolean>(false);
  const [isRequestPartOfGroup, setIsRequestPartOfGroup] = useState<boolean>(false);
  const { id } = useParams<{ id: string | undefined }>();
  const idNum = Number(id);
  const { currentUserData } = useCurrentUser();
  const {
    isBulkDeleted,
    setIsDeleted,
    setUserDeleteMessage,
    setRequestedUserEmail,
    setYourDeleteMessage,
    setIsBulkDeleted,
  } = useDeleteRequest();
  const navigate = useNavigate();
  const [confirmationAction, setConfirmationAction] = useState<RequestAction | null>(null);

  const handleDenyClose = () => {
    setDeny(false);
    setDenyEvent(false);
  };

  const { data, loading, error } = useQuery(GET_DATE_EVENT_BY_ID, {
    variables: {
      id: idNum,
    },
  });

  const showStatusModal = async (status: RequestStatus, isBulkRequest: boolean) => {
    if (!isBulkRequest) {
      status === RequestStatus.Denied ? setDeny(true) : setApproved(true);
    }
    if (isBulkRequest) {
      status === RequestStatus.Denied ? setDenyEvent(true) : setApprovedEvent(true);
    }
    setShowModal(false);
  };
  const [bulkDeleteRequests] = useMutation(BULK_DELETE_EVENTS, {
    onCompleted: async (data) => {
      const { bulkDeleteRequests } = data;
      const username = bulkDeleteRequests[0].user.username;
      const userEmail = bulkDeleteRequests[0].user.email;
      const eventType = bulkDeleteRequests[0].eventType.name;
      const userDeleteMessage = `${username}'s ${isBulkRequest && isBulkDeleted ? 'bulk' : ''} ${eventType} request has been deleted!`;
      const yourDeleteMessage = `Your ${isBulkRequest && isBulkDeleted ? 'bulk' : ''} ${eventType} request has been deleted!`;
      setUserDeleteMessage(userDeleteMessage);
      setYourDeleteMessage(yourDeleteMessage);
      setRequestedUserEmail(userEmail);
      if (confirmationAction === RequestAction.Delete) setIsDeleted(true);

      cache.evict({ fieldName: 'dateEvents' });
      cache.evict({ fieldName: 'userByEmail' });
      cache.evict({ fieldName: 'users' });
      cache.gc();

      if (currentUserData?.userByEmail.role === Role.USER) {
        navigate('/requests');
      } else {
        if (confirmationAction === RequestAction.Delete) {
          navigate(`/requests/pending`);
        } else {
          navigate(`/requests/view/${id}`);
        }
      }
    },
    onError: (error) => {
      throw new Error('There was an error with bulk events', error);
    },
  });

  const [bulkApproveOrDeny, { loading: bulkApproveDenyLoading }] = useMutation(
    BULK_APPROVE_OR_DENY_DATE_EVENT,
    {
      onCompleted: async (data) => {
        const { bulkApproveOrDeny } = data;
        // All bulk requests are for the same duration per day and same request type so just using the first index
        const event = bulkApproveOrDeny[0];
        const { status } = event;
        showStatusModal(status, true);
        cache.evict({ fieldName: 'userByEmail' });
        cache.evict({ fieldName: 'dateEvents' });
        cache.evict({ fieldName: 'users' });
        cache.gc();

        if (confirmationAction === RequestAction.Delete) {
          setIsDeleted(true);
        }

        if (currentUserData?.userByEmail.role === Role.USER) {
          navigate('/requests');
        } else {
          if (confirmationAction === RequestAction.Delete) {
            navigate(`/requests/pending`);
          } else {
            navigate(`/requests/view/${id}`);
          }
        }
      },
      onError: (error) => {
        throw new Error('There was an error with bulk events', error);
      },
    }
  );

  const [updateUser] = useMutation(UPDATE_USER, {
    refetchQueries: () => [
      { query: GET_USER_BY_EMAIL, variables: { email: currentUserData?.userByEmail.email } },
    ],
    awaitRefetchQueries: true,
    onCompleted: () => {
      if (confirmationAction === RequestAction.Delete) setIsDeleted(true);
      if (currentUserData?.userByEmail.role === Role.USER) {
        navigate('/requests');
      } else {
        if (confirmationAction === RequestAction.Delete) {
          navigate(`/requests/pending`);
        } else {
          navigate(`/requests/view/${id}`);
        }
      }
    },
    onError: (error) => {
      throw new Error('There was an error updating the user', error);
    },
  });

  const [approveOrDenySingleRequest, { loading: approveOrDenySingleRequestLoading }] = useMutation(
    APPROVE_OR_DENY_SINGLE_DATE_EVENT,
    {
      onCompleted: async (data) => {
        const { approveOrDenySingleRequest } = data;
        const { status } = approveOrDenySingleRequest;
        showStatusModal(status, false);
        cache.evict({ fieldName: 'userByEmail' });
        cache.evict({ fieldName: 'users' });
        cache.evict({ fieldName: 'dateEvents' });
        cache.gc();
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );

  const [removeDateEvent, { loading: removeDateEventLoading }] = useMutation(REMOVE_DATE_EVENT, {
    onCompleted: async (data) => {
      cache.evict({ fieldName: 'dateEvents' });
      cache.evict({ fieldName: 'users' });

      const { removeDateEvent } = data;
      const username = removeDateEvent.user?.username;
      const userEmail = removeDateEvent.user?.email;
      const eventType = removeDateEvent.eventType?.name;
      const userDeleteMessage = `${username}'s ${isBulkRequest && isBulkDeleted ? 'bulk' : ''} ${eventType} request has been deleted!`;
      const yourDeleteMessage = `Your ${isBulkRequest && isBulkDeleted ? 'bulk' : ''} ${eventType} request has been deleted!`;
      setUserDeleteMessage(userDeleteMessage);
      setYourDeleteMessage(yourDeleteMessage);
      setRequestedUserEmail(userEmail);
    },
    onError: (error) => {
      toast.error(`There was an error deleting this event. ${error.message}`);
    },
  });

  const formattedStartDateTime = DateTime.fromISO(data?.dateEventById?.start).toFormat(
    'MMM d, yyyy h:mm a'
  );
  const formattedEndtDateTime = DateTime.fromISO(data?.dateEventById?.end).toFormat(
    'MMM d, yyyy h:mm a'
  );

  const handleApproveClose = () => {
    setApproved(false);
    setApprovedEvent(false);
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (approved || deny || approvedEvent || denyEvent) {
      timer = setTimeout(() => {
        setApproved(false);
        setApprovedEvent(false);
        setDeny(false);
        setDenyEvent(false);
      }, 2500);
    }
    return () => clearTimeout(timer);
  }, [approved, deny, approvedEvent, denyEvent, setDeny, setDenyEvent]);

  useEffect(() => {
    if (error) {
      navigate(`/404`);
    }
  }, [error, navigate]);
  if (loading) return <Spinner />;

  const crumbs = `/requests/view/${data?.dateEventById?.user?.username}`.split('/');

  const userData = [
    { label: 'User', value: data?.dateEventById.user.username },
    { label: 'Type', value: data?.dateEventById?.eventType?.name },
    { label: 'Status', value: data?.dateEventById?.status },
    {
      label: 'Reason',
      value: (
        <span
          style={{
            display: 'inline-block',
            overflowY: 'auto',
            overflowX: 'auto',
            width: '18.5rem',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
          }}
        >
          {data?.dateEventById?.reasonRequested}
        </span>
      ),
    },
    { label: 'Start', value: formattedStartDateTime },
    { label: 'End', value: formattedEndtDateTime },
    { label: 'Owner', value: data?.dateEventById?.user?.displayName },
    ...(data?.dateEventById?.status === RequestStatus.Denied
      ? [
          {
            label: 'Status Reason',
            value: (
              <span
                style={{
                  display: 'inline-block',
                  overflowY: 'auto',
                  overflowX: 'auto',
                  width: '18.5rem',
                  scrollbarWidth: 'none',
                  msOverflowStyle: 'none',
                }}
              >
                {data?.dateEventById?.statusReason}
              </span>
            ),
          },
        ]
      : []),
    { label: 'Remaining PTO', value: formatNumberWithDecimal(data?.dateEventById?.user?.ptoHours) },
    { label: 'Remaining Flex', value: data?.dateEventById?.user?.flexDays },
    { label: 'Remaining WFH', value: data?.dateEventById?.user?.wfhDays },
    { label: 'Remaining Exception', value: data?.dateEventById?.user?.exceptionDays },
  ];

  const isBulkRequest = !!data?.dateEventById.groupId;

  const handleReturnBack = () => {
    setShowDeleteModal(false);
    setShowModal(false);
    // when cancel button in popup is clicked, the isStatusEmpty state is changed to false to remove the error message within the popup
    setIsStatusEmpty(false);
    // when cancel button in popup is clicked, the statusReasonForDeny is changed to be empty to allow reseting of state
    setStatusReason('');
  };
  const handleClickDelete = () => {
    setShowDeleteModal(true);
  };

  // moving logic here for when the 'delete this request' link is clicked.
  const handleDeletingRequest = () => {
    // if eventType is pto, then do the logic in the 'if' block. here we are essentially adding the hours of a current request back to the available pto a user has
    // if eventType is wfh or flex, then do the logic in the 'else if' block. here we are adding 1 to the available wfh or flex a user has
    if (
      currentUserData?.userByEmail.role !== 'ADMIN' &&
      (data?.dateEventById.status === RequestStatus.Approved ||
        data?.dateEventById.status === RequestStatus.Denied)
    )
      return;

    if (
      data?.dateEventById.eventType?.code === 'pto' &&
      data?.dateEventById.status !== RequestStatus.Denied
    ) {
      updateUser({
        variables: {
          input: {
            id: data?.dateEventById.user.id,
            ptoHours: data?.dateEventById.user.ptoHours + data?.dateEventById.hours,
          },
        },
      });
    } else if (
      data?.dateEventById.eventType?.code === 'pto' &&
      data?.dateEventById.status === RequestStatus.Denied
    ) {
      updateUser({
        variables: {
          input: {
            id: data?.dateEventById.user.id,
            ptoHours: data?.dateEventById.user.ptoHours,
          },
        },
      });
    } else if (
      data?.dateEventById.eventType?.code === 'unpaid' &&
      data?.dateEventById.status !== RequestStatus.Denied
    ) {
      const currentUnpaidHours = data?.dateEventById.user.unpaidHours ?? 0;
      updateUser({
        variables: {
          input: {
            id: data?.dateEventById.user.id,
            unpaidHours: currentUnpaidHours - data?.dateEventById.hours,
          },
        },
      });
    } else if (
      data?.dateEventById.eventType?.code === 'unpaid' &&
      data?.dateEventById.status === RequestStatus.Denied
    ) {
      const currentUnpaidHours = data?.dateEventById.user.unpaidHours ?? 0;
      updateUser({
        variables: {
          input: {
            id: data?.dateEventById.user.id,
            unpaidHours: currentUnpaidHours,
          },
        },
      });
    } else if (
      (data?.dateEventById.eventType?.code === 'wfh' ||
        data?.dateEventById.eventType?.code === 'flex' ||
        data?.dateEventById.eventType?.code === 'exception') &&
      data?.dateEventById.status !== RequestStatus.Denied
    ) {
      const wfhDays = data?.dateEventById.user.wfhDays ?? 0;
      const flexDays = data?.dateEventById.user.flexDays ?? 0;
      const exceptionDays = data?.dateEventById.user.exceptionDays ?? 0;

      const updatedFields = {
        id: data?.dateEventById.user.id,
        wfhDays: data?.dateEventById.eventType?.code === 'wfh' ? wfhDays + 1 : wfhDays,
        flexDays: data?.dateEventById.eventType?.code === 'flex' ? flexDays + 1 : flexDays,
        exceptionDays:
          data?.dateEventById.eventType?.code === 'exception' ? exceptionDays + 1 : exceptionDays,
      };
      updateUser({
        variables: {
          input: updatedFields,
        },
      });
    } else if (
      (data?.dateEventById.eventType?.code === 'wfh' ||
        data?.dateEventById.eventType?.code === 'flex' ||
        data?.dateEventById.eventType?.code === 'exception') &&
      data?.dateEventById.status === RequestStatus.Denied
    ) {
      updateUser({
        variables: {
          input: {
            id: currentUserData?.userByEmail.id,
          },
        },
      });
    }
    removeDateEvent({
      variables: {
        id: idNum,
        userRole: currentUserData?.userByEmail.role,
      },
    });
    setShowDeleteModal(false);
  };

  const handleDeletingBulkRequest = async () => {
    setIsBulkDeleted(true);
    await bulkDeleteRequests({
      variables: {
        groupId: data?.dateEventById.groupId,
      },
    });
    setShowDeleteModal(false);
  };

  // this handles part of logic for denying a single request
  const handleDenyingSingleRequest = async () => {
    await approveOrDenySingleRequest({
      variables: {
        input: {
          id: idNum,
          status: RequestStatus.Denied,
          statusReason: statusReason,
        },
      },
    });
  };

  const handleApprovingSingleRequest = async () => {
    await approveOrDenySingleRequest({
      variables: {
        input: {
          id: idNum,
          status: RequestStatus.Approved,
          statusReason: statusReason,
        },
      },
    });
  };

  // this handles part of logic for denying all requests in a bulk request
  const handleDenyingMultipleRequests = async () => {
    if (!statusReason.trim()) {
      setIsStatusEmpty(true);
      return;
    }

    // this was moved from the onClick function on Deny Group button
    await bulkApproveOrDeny({
      variables: {
        input: {
          groupId: data?.dateEventById.groupId,
          status: RequestStatus.Denied,
          statusReason: statusReason,
        },
      },
    });
  };

  const handleApprovingMultipleRequests = async () => {
    await bulkApproveOrDeny({
      variables: {
        input: {
          groupId: data?.dateEventById.groupId,
          status: RequestStatus.Approved,
          statusReason: statusReason,
        },
      },
    });
  };

  return (
    <>
      <BuildBreadcrumbs path={crumbs} isBreadcrumbsRendered={true} />
      {deny && (
        <ClickAwayListener onClickAway={handleDenyClose}>
          <Alert
            sx={{ justifyContent: 'center', width: '17.5rem', margin: '0 auto', marginTop: '1rem' }}
            variant='filled'
            severity='warning'
          >
            Request denied.
          </Alert>
        </ClickAwayListener>
      )}

      {denyEvent && (
        <ClickAwayListener onClickAway={handleDenyClose}>
          <Alert
            sx={{ justifyContent: 'center', width: '17.5rem', margin: '0 auto', marginTop: '1rem' }}
            variant='filled'
            severity='warning'
          >
            Request group denied.
          </Alert>
        </ClickAwayListener>
      )}
      {approved && (
        <ClickAwayListener onClickAway={handleApproveClose}>
          <Alert
            sx={{ justifyContent: 'center', width: '17.5rem', margin: '0 auto', marginTop: '1rem' }}
            variant='filled'
            severity='success'
          >
            Request approved.
          </Alert>
        </ClickAwayListener>
      )}
      {approvedEvent && (
        <ClickAwayListener onClickAway={handleApproveClose}>
          <Alert
            sx={{ justifyContent: 'center', width: '17.5rem', margin: '0 auto', marginTop: '1rem' }}
            variant='filled'
            severity='success'
          >
            Request group approved.
          </Alert>
        </ClickAwayListener>
      )}
      {data?.dateEventById.status === RequestStatus.Approved &&
      currentUserData?.userByEmail.role === Role.USER ? (
        <Alert
          sx={{
            justifyContent: 'center',
            width: { xs: '17.5rem', sm: '20rem' },
            margin: '0 auto',
            marginTop: '1rem',
            marginBottom: '0.5rem',
          }}
          variant='filled'
          severity='success'
        >
          Your request has been approved and cannot be deleted. For further assistance, please
          contact Operations.
        </Alert>
      ) : (
        ''
      )}
      {data?.dateEventById.status === RequestStatus.Denied &&
      currentUserData?.userByEmail.role === Role.USER ? (
        <Alert
          sx={{
            justifyContent: 'center',
            width: { xs: '17.5rem', sm: '20rem' },
            margin: '0 auto',
            marginTop: '1rem',
            marginBottom: '0.5rem',
          }}
          variant='filled'
          severity='warning'
        >
          Your request has been denied and cannot be deleted. For further assistance, please contact
          Operations.
        </Alert>
      ) : (
        ''
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '20rem',
          '@media (max-width: 23.44rem)': {
            width: '18.5rem',
          },
          boxShadow: '1rem 1rem 2rem #00000033',
          borderRadius: '0.3rem',
          padding: '1rem',
          '@media (max-width: 21.25rem)': {
            padding: 1,
          },
          marginTop: '1rem',
          marginBottom: '2rem',
          backgroundColor: 'white',
        }}
      >
        {currentUserData?.userByEmail.role === Role.ADMIN ||
        data?.dateEventById.status === RequestStatus.Pending ? (
          <Box sx={{ textAlign: 'left', marginY: '0.5rem' }}>
            <ReusableButton aria-label='Edit request' style={{ width: '0.1rem', padding: 0 }}>
              <EditIcon onClick={() => navigate(`/requests/edit/${id}`)} />
            </ReusableButton>
          </Box>
        ) : (
          ''
        )}
        {userData.map((item, i) => (
          <UserInfo
            key={i}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '100%',
              paddingLeft: 0,
              paddingBottom: item.label === 'Reason' || item.label === 'Status Reason' ? 0 : '',
            }}
          >
            <span style={{ fontWeight: 'bold' }}>
              {item.label}: <span style={{ fontWeight: 'normal' }}>{item.value}</span>
            </span>
          </UserInfo>
        ))}

        {data?.dateEventById.updated ? (
          <UserInfo sx={{ paddingLeft: 0 }}>
            <span style={{ fontWeight: 'normal' }}>{'This request has been edited'}</span>
          </UserInfo>
        ) : (
          ''
        )}

        {currentUserData?.userByEmail.role !== Role.USER && (
          <>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '0.1rem',
                width: '100%',
                justifyContent: 'flex-start',
                marginTop: '0.5rem',
              }}
            >
              <ReusableButton
                aria-label='Deny request'
                style={{
                  height: '2rem',
                  width: '9rem',
                  backgroundColor: '#686868',
                  border: '3px solid #686868',
                  marginRight: '0.3rem',
                }}
                disabled={
                  data?.dateEventById.status === RequestStatus.Denied ||
                  approveOrDenySingleRequestLoading
                }
                onClick={() => {
                  setConfirmationAction(RequestAction.Denied);
                  setIsRequestPartOfGroup(false);
                  setShowModal(true);
                }}
              >
                Deny
              </ReusableButton>
              <ReusableButton
                aria-label='Approve request'
                style={{ height: '2rem', width: '9rem', marginBottom: '0.1rem' }}
                disabled={
                  data?.dateEventById.status === RequestStatus.Approved ||
                  approveOrDenySingleRequestLoading
                }
                onClick={() => {
                  setConfirmationAction(RequestAction.Approved);
                  setIsRequestPartOfGroup(false);
                  setShowModal(true);
                }}
              >
                Approve
              </ReusableButton>
              {isBulkRequest && (
                <ReusableButton
                  aria-label='Deny group request'
                  style={{
                    height: '2rem',
                    width: '9rem',
                    lineHeight: '1.25rem',
                    backgroundColor: '#686868',
                    border: '#686868',
                    marginRight: '0.3rem',
                  }}
                  disabled={
                    data?.dateEventById.status === RequestStatus.Denied || bulkApproveDenyLoading
                  }
                  // clicking Deny Group button now opens modal and signifies that this individual request is part of a bulk request
                  onClick={() => {
                    setConfirmationAction(RequestAction.Denied);
                    setIsRequestPartOfGroup(true);
                    setShowModal(true);
                  }}
                >
                  Deny Group
                </ReusableButton>
              )}
              {isBulkRequest && (
                <ReusableButton
                  aria-label='Approve group request'
                  style={{ height: '2rem', width: '9rem', lineHeight: '1.25rem' }}
                  disabled={
                    data?.dateEventById.status === RequestStatus.Approved || bulkApproveDenyLoading
                  }
                  onClick={() => {
                    setConfirmationAction(RequestAction.Approved);
                    setIsRequestPartOfGroup(true);
                    setShowModal(true);
                  }}
                >
                  Approve Group
                </ReusableButton>
              )}
            </Box>
            <Link
              disabled={removeDateEventLoading}
              component='button'
              variant='body2'
              sx={{
                textAlign: 'left',
                textDecoration: 'none',
                marginTop: '1rem',
                color: '#686868',
                fontSize: '1rem',
                marginBottom: '0.5rem',
              }}
              onClick={() => {
                setConfirmationAction(RequestAction.Delete);
                handleClickDelete();
                setIsRequestPartOfGroup(false);
              }}
            >
              Delete this request
            </Link>
            {isBulkRequest && (
              <Link
                disabled={removeDateEventLoading}
                component='button'
                variant='body2'
                sx={{
                  textAlign: 'left',
                  textDecoration: 'none',
                  color: '#686868',
                  fontSize: '1rem',
                  marginBottom: '0.5rem',
                }}
                onClick={() => {
                  setConfirmationAction(RequestAction.Delete);
                  setIsRequestPartOfGroup(true);
                  handleClickDelete();
                }}
              >
                Bulk delete requests
              </Link>
            )}
          </>
        )}
        {currentUserData?.userByEmail.role === Role.USER &&
          data?.dateEventById.status === RequestStatus.Pending && (
            <Link
              disabled={removeDateEventLoading}
              component='button'
              variant='body2'
              sx={{
                textAlign: 'left',
                textDecoration: 'none',
                marginTop: '0.2rem',
                marginBottom: '0.5rem',
                color: '#686868',
                fontSize: '1rem',
              }}
              onClick={() => {
                setConfirmationAction(RequestAction.Delete);
                handleClickDelete();
              }}
            >
              Delete this request
            </Link>
          )}
        {isBulkRequest &&
          currentUserData?.userByEmail.role === Role.USER &&
          data?.dateEventById.status === RequestStatus.Pending && (
            <Link
              disabled={removeDateEventLoading}
              component='button'
              variant='body2'
              sx={{
                textAlign: 'left',
                textDecoration: 'none',
                color: '#686868',
                fontSize: '1rem',
                marginBottom: '0.5rem',
              }}
              onClick={() => {
                setConfirmationAction(RequestAction.Delete);
                setIsRequestPartOfGroup(true);
                handleClickDelete();
              }}
            >
              Bulk delete requests
            </Link>
          )}
        {showDeleteModal && (
          <DeleteModalComponent
            open={showDeleteModal}
            isBulkRequest={isBulkRequest}
            isRequestPartOfGroup={isRequestPartOfGroup}
            onClose={
              isBulkRequest && isRequestPartOfGroup
                ? handleDeletingBulkRequest
                : handleDeletingRequest
            }
            onReturnBack={handleReturnBack}
          />
        )}
        {showModal && (
          <ConfirmationModalComponent
            open={showModal}
            setStatusReason={setStatusReason}
            setIsStatusEmpty={setIsStatusEmpty}
            isStatusEmpty={isStatusEmpty}
            // the confirm button in the popup modal can handle two different functions depending on if the request is a single request or part of a bulk request
            onCloseSingleDeny={handleDenyingSingleRequest}
            onCloseSingleApprove={handleApprovingSingleRequest}
            onCloseMultipleApprove={handleApprovingMultipleRequests}
            confirmationAction={confirmationAction}
            onCloseMultipleDeny={handleDenyingMultipleRequests}
            onReturnBack={handleReturnBack}
            isRequestPartOfGroup={isRequestPartOfGroup}
            loading={bulkApproveDenyLoading || approveOrDenySingleRequestLoading}
            statusReason={statusReason}
          />
        )}
      </Box>
    </>
  );
};

export default UserRequest;
