import { Box, Divider } from '@mui/material';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import { useCurrentUser } from '../../providers/CurrentUserContextProvider';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { formatNumberWithDecimal } from '../../helpers/formattingAccruedBalance';

const Container = styled(Box)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  margin-top: 1rem;
  @media (min-width: 20rem) {
    margin-right: 0;
  }
  @media (min-width: 25rem) {
    margin-right: 1rem;
  }
  @media (min-width: 28.125rem) {
    margin-right: 0;
  }
`;

const EmployeeHourContainer = styled(Box)`
  display: flex;
  margin: 0 auto;
  margin-top: 0.4rem;
  flex-direction: row;

  align-items: center;
  gap: 0.025rem;
  width: 27rem;

  @media (min-width: 20rem) {
    width: 18rem;
  }

  @media (min-width: 25rem) {
    width: 23rem;
  }

  @media (min-width: 28.125rem) {
    width: 27rem;
  }
`;
const EmployeeHourType = styled(Typography)`
  display: flex;
  justify-content: center;
  text-align: left;
  font-size: 0.9rem;

  whitespace: normal;
  wordbreak: break-word;
  font-weight: bold;
`;

export const EmployeeHour = styled(Typography)`
  display: flex;
  text-align: left;
  font-size: 1rem;
`;

const ResponsiveDivider = styled(Divider)`
  margin: 0 auto;
  border-color: black;
  border-width: 0.05rem;
  @media (min-width: 20rem) {
    width: 16.5rem;
  }
  @media (min-width: 25rem) {
    width: 20rem;
  }
  @media (min-width: 28.125rem) {
    width: 24.5rem;
  }
`;

const UserStats = () => {
  const { currentUserData } = useCurrentUser();
  const user = currentUserData?.userByEmail;
  const isDataAvailable =
    typeof user?.ptoHours === 'number' &&
    typeof user?.flexDays === 'number' &&
    typeof user?.wfhDays === 'number' &&
    typeof user?.exceptionDays === 'number';
  const location = useLocation();
  const path = location.pathname;

  const [showBreak, setShowBreak] = useState<boolean>(window.innerWidth <= 432);

  useEffect(() => {
    const handleResize = () => {
      setShowBreak(window.innerWidth <= 449);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Container>
      <EmployeeHourContainer>
        {path !== '/requests/pending' && (
          <>
            <EmployeeHourType
              sx={{
                width: '8rem',
                marginLeft: '0.2rem',
                '@media (min-width: 20rem)': {
                  marginLeft: 0,
                  width: '7.5rem',
                },
                '@media (min-width: 25rem)': {
                  marginLeft: 0,
                  width: '7rem',
                },
                '@media (min-width: 28.125rem)': {
                  marginLeft: '0.2rem',
                  width: '7rem',
                },
              }}
            >
              {' '}
              PTO {showBreak && <br />}Hours*{' '}
            </EmployeeHourType>
            <EmployeeHourType
              sx={{
                width: '7rem',
                '@media (min-width: 25rem)': {
                  marginLeft: 0,
                  width: '5.5rem',
                },
              }}
            >
              {' '}
              WFH {showBreak && <br />}Days{' '}
            </EmployeeHourType>
            <EmployeeHourType
              sx={{
                width: '5rem',
                '@media (min-width: 25rem)': {
                  marginLeft: '0.5rem',
                },
              }}
            >
              {' '}
              Flex {showBreak && <br />}Days{' '}
            </EmployeeHourType>
            <EmployeeHourType
              sx={{
                width: '10rem',
                '@media (min-width: 25rem)': {
                  marginLeft: '0.3rem',
                  width: '8rem',
                },
                '@media (min-width: 28.125rem)': {
                  marginLeft: '0.3rem',
                  width: '8rem',
                },
              }}
            >
              {' '}
              Exception {showBreak && <br />}Time{' '}
            </EmployeeHourType>
          </>
        )}
      </EmployeeHourContainer>
      <ResponsiveDivider
        orientation='horizontal'
        variant='middle'
        color='black'
        sx={{ margin: '0 auto' }}
      />
      {isDataAvailable && (
        <EmployeeHourContainer>
          {path !== '/requests/pending' && user && (
            <>
              <EmployeeHour
                sx={{
                  '@media (min-width: 20rem)': {
                    marginLeft: '1rem',
                    width: '4rem',
                  },
                  '@media (min-width: 25rem)': {
                    marginLeft: '1.8rem',
                    width: '5.2rem',
                  },
                  '@media (min-width: 28.125rem)': {
                    marginLeft: '1.4rem',
                    width: '6rem',
                  },
                }}
              >
                {formatNumberWithDecimal(user.ptoHoursRemaining)}
              </EmployeeHour>
              <EmployeeHour
                sx={{
                  '@media (min-width: 20rem)': {
                    marginLeft: '0.7rem',
                    width: '3rem',
                  },
                  '@media (min-width: 25rem)': {
                    marginLeft: '0.5rem',
                    width: '4.5rem',
                  },
                  '@media (min-width: 28.125rem)': {
                    marginLeft: '0.6rem',
                    width: '5rem',
                  },
                }}
              >
                {user.wfhDays}
              </EmployeeHour>
              <EmployeeHour
                sx={{
                  '@media (min-width: 20rem)': {
                    marginLeft: '0.7rem',
                    width: '3rem',
                  },
                  '@media (min-width: 25rem)': {
                    marginLeft: '0.5rem',
                    width: '5rem',
                  },
                  '@media (min-width: 28.125rem)': {
                    marginLeft: '0.9rem',
                    width: '5rem',
                  },
                }}
              >
                {user.flexDays}
              </EmployeeHour>
              <EmployeeHour
                sx={{
                  '@media (min-width: 20rem)': {
                    width: '3.3rem',
                    marginLeft: '0.6rem',
                  },
                  '@media (min-width: 25rem)': {
                    marginLeft: 0,
                    width: '5rem',
                  },
                  '@media (min-width: 28.125rem)': {
                    marginLeft: '0.6rem',
                    width: '5rem',
                  },
                }}
              >
                {user.exceptionDays}
              </EmployeeHour>
            </>
          )}
        </EmployeeHourContainer>
      )}
    </Container>
  );
};

export default UserStats;
