import { gql } from '@apollo/client';

export const GET_USER_BY_ID = gql`
  query getUserById($id: Int!) {
    userById(id: $id) {
      email
      emailSubscriptions
      flexDays
      id
      ptoHours
      ptoRate
      role
      startDate
      username
      wfhDays
      auth0Id
      displayName
      exceptionDays
      unpaidHours
    }
  }
`;
