import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import ReusableButton from '../components/buttons/ReusableButton';
import { useCurrentUser } from '../providers/CurrentUserContextProvider';
import UserStats from '../components/userStats/UserStats';
import { Link, useNavigate } from 'react-router-dom';
import TimeUntilResets, { PTOHour } from '../components/userStats/TimeUntilResets';
import { useEffect } from 'react';
import { formatNumberWithDecimal } from '../helpers/formattingAccruedBalance';

//ommitting these imports until needed
//import { Dialog, DialogContent, DialogContentText, DialogActions } from '@mui/material';

export const DashboardContainer = styled(Box)`
  display: flex;

  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 1.5rem;
  height: auto;
  gap: 0.75rem;

  @media (min-width: 20rem) {
    width: 18rem;
  }

  @media (min-width: 25rem) {
    width: 23rem;
  }

  @media (min-width: 28.125rem) {
    width: 27rem;
  }
`;

const Greeting = styled(Typography)`
  display: flex;
  width: 75%;
  font-size: 1.5rem;
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
  padding-right: 2.5rem;
  font-weight: bold;
  margin: 0 auto;

  @media (min-width: 20rem) {
    width: 14rem;
    margin-left: 0.7rem;
  }
  @media (min-width: 25rem) {
    width: 18rem;
    margin-left: 1.5rem;
  }
  @media (min-width: 28.125rem) {
    width: 22rem;
    margin-left: 1.2rem;
  }
`;

const Container = styled(Box)`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  max-width: 40rem;
`;

// PTOHour spreads a bit farther than dashboard buttons-needs fixing.
export const ResponsiveButton = styled(ReusableButton)(() => ({
  width: '92%',
}));

const Dashboard = () => {
  const { currentUserData } = useCurrentUser();
  const user = currentUserData?.userByEmail;

  const navigate = useNavigate();

  useEffect(() => {
    const redirectUrl = localStorage.getItem('wtf.redirect_url');

    if (redirectUrl && redirectUrl !== '/dashboard') {
      localStorage.removeItem('wtf.redirect_url');
      navigate(redirectUrl, { replace: true });
    }
  }, [navigate]);

  // this useState hook is commented out as it is part of the PTO popup that we are commenting out for now
  // const [open, setOpen] = useState<boolean>(false);

  // commenting out the below useEffect hook that deals

  // useEffect(() => {
  //   // if the user has more than 2 days of PTO left, and less than 15 days before reset, trigger popup
  //   if (user?.ptoDays && user.ptoDays > 2 && daysUntilAnniversary < 15 && daysUntilAnniversay !== 0) {
  //     setOpen(true);
  //   }
  // }, [user?.ptoDays, daysUntilAnniversary]);

  // const handleClose = () => {
  //   setOpen(false);
  // };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '1rem 1rem 2rem #00000033',
        borderRadius: '0.5rem',
        marginTop: '1.5rem',
        marginBottom: '3rem',
        backgroundColor: 'white',
      }}
    >
      {/* Commenting out the feature below which prompts the user to take time off. Need to investigate how to only allow the prompt to appear once every 24 hours. */}

      {/*<Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <DialogContentText sx={{ textAlign: 'center' }}>
            Your PTO resets in <strong>{daysUntilAnniversary}</strong>{' '}
            {daysUntilAnniversary === 1 ? 'day' : 'days'}!
            <br />
            Take some time off?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ReusableButton variant='outlined' href='/requests/pto'>
            Request PTO
          </ReusableButton>
          <ReusableButton variant='outlined' onClick={handleClose}>
            Not Now
          </ReusableButton>
        </DialogActions>
  </Dialog>*/}

      <Greeting> Hello{user?.displayName ? `, ${user.displayName}` : ''}</Greeting>
      <Box
        sx={{
          width: '20rem',
          '@media (min-width: 20rem)': {
            width: '16rem',
          },
          '@media (min-width: 25rem)': {
            width: '21rem',
          },
          '@media (min-width: 28.125rem)': {
            width: '24.5rem',
          },
          margin: '1rem auto 0rem auto',
          marginBottom: -1.5,
        }}
      >
        <Typography
          sx={{
            '@media (min-width: 20rem)': {
              marginLeft: '-0.3rem',
            },
            '@media (min-width: 25rem)': {
              marginLeft: 0,
            },
          }}
        >
          Your time available for scheduling:
        </Typography>
      </Box>
      <Container>
        <UserStats />
      </Container>

      <TimeUntilResets />

      <Box
        sx={{
          width: '20rem',
          '@media (min-width: 20rem)': {
            width: '16rem',
          },
          '@media (min-width: 25rem)': {
            width: '21rem',
          },
          '@media (min-width: 28.125rem)': {
            width: '24.5rem',
          },
          margin: '1rem auto 0rem auto',
        }}
      >
        <Link to='/requests' style={{ color: 'black', width: 'full' }}>
          <Typography>View my upcoming requests</Typography>
        </Link>
      </Box>

      <DashboardContainer style={{ flexDirection: 'column' }}>
        <ResponsiveButton aria-label='Request Time Off' variant='outlined' href='/requests/pto'>
          {' '}
          Request Time Off{' '}
        </ResponsiveButton>
        <ResponsiveButton
          aria-label='Request Exception Time'
          variant='outlined'
          href='/requests/exception'
        >
          {' '}
          Request Exception Time{' '}
        </ResponsiveButton>
        <ResponsiveButton
          aria-label='Request Work From Home'
          variant='outlined'
          href='/requests/wfh'
        >
          {' '}
          Request Work From Home{' '}
        </ResponsiveButton>
        <ResponsiveButton aria-label='Request Flex' variant='outlined' href='/requests/flex'>
          {' '}
          Request Flex{' '}
        </ResponsiveButton>
        <ResponsiveButton aria-label='Event History' variant='outlined' href='/event-history'>
          {' '}
          Event History{' '}
        </ResponsiveButton>
        {user && (
          <PTOHour style={{ paddingBottom: '2rem', width: '92%', marginTop: '0.5rem' }}>
            *Note: PTO Hours are front-loaded for your convenience, but do accrue over time. Your
            actual accrued balance is <strong>{formatNumberWithDecimal(user.ptoHours)}</strong>{' '}
            hours
          </PTOHour>
        )}
      </DashboardContainer>
    </Box>
  );
};

export default Dashboard;
