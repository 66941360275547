import { gql } from '@apollo/client';

export const GET_DATE_EVENTS_FILTERED = gql`
  query dateEventsFiltered($input: FindAllFilteredDateEventInput) {
    dateEventsFiltered(input: $input) {
      id
      start
      end
      eventType {
        code
      }
      status
      hours
    }
  }
`;
