import { createContext, ReactNode, useContext, useState } from 'react';

export interface DeleteRequestContextType {
  isDeleted: boolean;
  setIsDeleted: React.Dispatch<React.SetStateAction<boolean>>;
  userDeleteMessage: string;
  setUserDeleteMessage: React.Dispatch<React.SetStateAction<string>>;
  requestedUserEmail: string;
  setRequestedUserEmail: React.Dispatch<React.SetStateAction<string>>;
  yourDeleteMessage: string;
  setYourDeleteMessage: React.Dispatch<React.SetStateAction<string>>;
  isBulkDeleted: boolean;
  setIsBulkDeleted: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useDeleteRequest = () => {
  return useContext(DeleteRequestContext);
};

export const DeleteRequestContext = createContext<DeleteRequestContextType>({
  isDeleted: false,
  setIsDeleted: () => null,
  userDeleteMessage: '',
  setUserDeleteMessage: () => null,
  requestedUserEmail: '',
  setRequestedUserEmail: () => null,
  yourDeleteMessage: '',
  setYourDeleteMessage: () => null,
  isBulkDeleted: false,
  setIsBulkDeleted: () => null,
});

const DeleteRequestProvider = ({ children }: { children: ReactNode }) => {
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const [userDeleteMessage, setUserDeleteMessage] = useState<string>('');
  const [requestedUserEmail, setRequestedUserEmail] = useState<string>('');
  const [yourDeleteMessage, setYourDeleteMessage] = useState<string>('');
  const [isBulkDeleted, setIsBulkDeleted] = useState<boolean>(false);

  return (
    <DeleteRequestContext.Provider
      value={{
        isDeleted,
        setIsDeleted,
        userDeleteMessage,
        setUserDeleteMessage,
        requestedUserEmail,
        setRequestedUserEmail,
        yourDeleteMessage,
        setYourDeleteMessage,
        isBulkDeleted,
        setIsBulkDeleted,
      }}
    >
      {' '}
      {children}
    </DeleteRequestContext.Provider>
  );
};

export default DeleteRequestProvider;
