import LoginLogout from '../components/LoginLogout';
import { Box } from '@mui/material';
import Spinner from '../components/Spinner';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import frogIcon from '../assets/images/frog-pixel-small.png';
import { CurrentUserContext } from '../providers/CurrentUserContextProvider';

const Login = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const { currentUserData } = useContext(CurrentUserContext);
  const navigate = useNavigate();

  const WelcomeText = styled(Typography)`
    color: ${({ theme }) => theme.colors.black};
    font-family: ${({ theme }) => theme.fontFamily};
    font-size: 2.5rem !important;
    margin-top: 1.5rem;

    @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
      font-size: 1.8rem !important;
    }
  `;

  const Container = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5rem;
  `;

  useEffect(() => {
    const loggedInNoUser =
      !isLoading && (currentUserData === null || currentUserData === undefined) && isAuthenticated;
    if (isAuthenticated && currentUserData) {
      navigate('/dashboard');
    }

    if (loggedInNoUser) {
      navigate('/login');
    }
  }, [currentUserData, isAuthenticated, isLoading, navigate]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Container>
      <WelcomeText>Sign in to Caxy WTF</WelcomeText>
      <Box sx={{ width: { xs: '100%', sm: '50%' }, display: 'flex', justifyContent: 'center' }}>
        <img alt='frog icon' src={frogIcon}></img>
      </Box>
      <Box sx={{ marginTop: '1rem', marginBottom: '2rem' }}>
        <LoginLogout isStylesDefault={true} />
      </Box>
    </Container>
  );
};

export default Login;
