import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { getDaysUntilAnniversary } from '../../utils/getDaysUntilAnniversary';
import { formatNumberWithDecimal } from '../../helpers/formattingAccruedBalance';
import { StyledTableHeader } from '../../routes/User/Events/PendingEvents';
import SortArrow from './SortArrow';
import { SortByInterface } from '../../helpers/sorting';
import { handleSort } from '../../helpers/handleSort';

interface TableData {
  username: string;
  email: string;
  startDate: Date;
  ptoHours: number;
  id: number;
  ptoHoursAvailable: number;
}

interface UserTableProps {
  data: TableData[];
  type: string;
}

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(0.5),
  textAlign: 'left',
  [theme.breakpoints.down('xs')]: {
    padding: theme.spacing(0.1),
  },
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(0.75),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(1),
  },
  fontSize: '1rem',
}));

const UserTable: React.FC<UserTableProps> = ({ data = [], type }) => {
  const [sortConfig, setSortConfig] = useState<SortByInterface>({ column: null, direction: 'ASC' });

  const { column, direction } = sortConfig;

  const onSort = useCallback(
    (columnName: string) => {
      const newDirection = handleSort(columnName, sortConfig);
      setSortConfig({ column: columnName, direction: newDirection });
    },
    [sortConfig, setSortConfig]
  );

  const sortedData = useMemo(() => {
    if (!data.length || column === null) return data;

    return [...data].sort((a, b) => {
      const isReversed = direction === 'DESC' ? 1 : -1;
      const aRatio =
        a.ptoHoursAvailable / getDaysUntilAnniversary(a.startDate).daysUntilAnniversary;
      const bRatio =
        b.ptoHoursAvailable / getDaysUntilAnniversary(b.startDate).daysUntilAnniversary;

      switch (column) {
        case 'user':
          return isReversed * a.username.localeCompare(b.username);
        case 'hoursRemaining':
          return isReversed * (a.ptoHoursAvailable - b.ptoHoursAvailable);
        case 'daysUntilAnniversary':
          return (
            isReversed *
            (getDaysUntilAnniversary(a.startDate).daysUntilAnniversary -
              getDaysUntilAnniversary(b.startDate).daysUntilAnniversary)
          );
        case 'ratio':
          return isReversed * (aRatio - bRatio);
        default:
          return 0;
      }
    });
  }, [data, column, direction]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        paddingTop: '1rem',
        width: '90%',
      }}
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableHeader
                sx={{ width: '10rem' }}
                onClick={() => onSort('user')}
                selected={column === 'user'}
              >
                <Stack direction='row'>
                  User
                  <SortArrow sortConfig={sortConfig} />
                </Stack>
              </StyledTableHeader>
              <StyledTableHeader
                sx={{ width: '8rem' }}
                onClick={() => onSort('hoursRemaining')}
                selected={column === 'hoursRemaining'}
              >
                <Stack direction='row'>
                  Hours Remaining
                  <SortArrow sortConfig={sortConfig} />
                </Stack>
              </StyledTableHeader>
              <StyledTableHeader
                sx={{ width: '10rem' }}
                onClick={() => onSort('daysUntilAnniversary')}
                selected={column === 'daysUntilAnniversary'}
              >
                <Stack direction='row'>
                  Days Until Anniversary
                  <SortArrow sortConfig={sortConfig} />
                </Stack>
              </StyledTableHeader>
              <StyledTableHeader
                sx={{
                  width: '10rem',
                }}
                onClick={() => onSort('ratio')}
                selected={column === 'ratio'}
              >
                <Stack direction='row' justifyContent={'space-between'}>
                  PTO Usage Ratio
                  <SortArrow sortConfig={sortConfig} />
                </Stack>
              </StyledTableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((user, index) => (
              <TableRow key={index}>
                <StyledTableCell
                  sx={{
                    maxWidth: '4rem',
                    overflowX: 'scroll',
                    '&::-webkit-scrollbar': {
                      display: 'none',
                    },
                    scrollbarWidth: 'none',
                  }}
                >
                  {user.username}
                </StyledTableCell>
                <StyledTableCell>{formatNumberWithDecimal(user.ptoHoursAvailable)}</StyledTableCell>
                <StyledTableCell>
                  {getDaysUntilAnniversary(user.startDate).daysUntilAnniversary}
                </StyledTableCell>
                <StyledTableCell style={{ cursor: 'pointer' }}>
                  {(
                    user.ptoHoursAvailable /
                    getDaysUntilAnniversary(user.startDate).daysUntilAnniversary
                  ).toFixed(2)}{' '}
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {data.length === 0 && (
        <Typography sx={{ marginTop: '1rem' }}>
          No {type.toLowerCase()} spend users to show.
        </Typography>
      )}
    </Box>
  );
};

export default UserTable;
