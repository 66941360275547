import React, { useCallback, useMemo, useState } from 'react';
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from '@mui/material';
import ReusableButton from '../buttons/ReusableButton';
import styled, { useTheme } from 'styled-components';
import formatDate from '../../utils/FormatDate';
import { useLocation, useNavigate } from 'react-router-dom';
import { capitalizeRequestType } from '../../utils/capitalizeRequestType';
import { formatNumberWithDecimal } from '../../helpers/formattingAccruedBalance';
import { StyledTableHeader } from '../../routes/User/Events/PendingEvents';
import { SortByInterface } from '../../helpers/sorting';
import SortArrow from './SortArrow';
import { handleSort } from '../../helpers/handleSort';

interface TableData {
  status: string;
  start: string;
  hours: number;
  id: number;
  eventType: { code: string };
}

interface TabTableProps {
  data: TableData[];
  type: string;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(0.5),
  [theme.breakpoints.down('xs')]: {
    padding: theme.spacing(0.1),
  },
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(0.75),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(1),
  },
  fontSize: '1rem',
}));

const StyledTableCellLeftAlign = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(0.75),
  textAlign: 'left',
  [theme.breakpoints.down('xs')]: {
    padding: theme.spacing(0.1),
  },
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(0.75),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(1),
  },
  fontSize: '1rem',
}));

const TabTable: React.FC<TabTableProps> = ({ data = [], type }) => {
  const location = useLocation();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('430'));
  const navigate = useNavigate();

  const [sortConfig, setSortConfig] = useState<SortByInterface>({ column: null, direction: 'ASC' });

  const { column, direction } = sortConfig;

  const onSort = useCallback(
    (columnName: string) => {
      const newDirection = handleSort(columnName, sortConfig);
      setSortConfig({ column: columnName, direction: newDirection });
    },
    [sortConfig, setSortConfig]
  );

  const sortedData = useMemo(() => {
    if (!data.length || !column) return data;
    const isReversed = direction === 'DESC' ? 1 : -1;

    return [...data].sort((a, b) => {
      const secondarySort = new Date(a.start).getTime() - new Date(b.start).getTime();

      switch (column) {
        case 'status':
          return isReversed * a.status.localeCompare(b.status) || secondarySort;
        case 'date':
          return isReversed * secondarySort;
        case 'hours':
          return isReversed * (a.hours - b.hours) || secondarySort;
        default:
          return 0;
      }
    });
  }, [data, column, direction]);

  return (
    <Box sx={{ display: 'flex', margin: '0 auto', paddingTop: '1rem', width: '100%' }}>
      <TableContainer sx={{ overflow: 'auto' }}>
        <Table stickyHeader>
          <TableHead sx={{ position: 'sticky', top: 0, zIndex: 1 }}>
            <TableRow>
              <StyledTableHeader
                sx={{ minWidth: '6rem' }}
                onClick={() => onSort('status')}
                selected={column === 'status'}
                sortConfig={sortConfig}
              >
                <Stack direction='row'>
                  Status
                  <SortArrow sortConfig={sortConfig} />
                </Stack>
              </StyledTableHeader>
              <StyledTableHeader
                sx={{ minWidth: '7rem' }}
                onClick={() => onSort('date')}
                selected={column === 'date'}
                sortConfig={sortConfig}
              >
                <Stack direction={'row'}>
                  Date
                  <SortArrow sortConfig={sortConfig} />
                </Stack>
              </StyledTableHeader>
              <StyledTableHeader
                sx={{ minWidth: '4rem' }}
                onClick={() => onSort('hours')}
                selected={column === 'hours'}
                sortConfig={sortConfig}
              >
                <Stack direction={'row'}>
                  Hours
                  <SortArrow sortConfig={sortConfig} />
                </Stack>
              </StyledTableHeader>
              {!sm && <StyledTableHeader sx={{ minWidth: '4rem' }}>Action</StyledTableHeader>}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.some((row) => type === row.eventType.code) ? (
              sortedData.map((row, index) =>
                type === row.eventType.code ? (
                  <TableRow
                    key={`${row.start}-${index}`}
                    onClick={() =>
                      sm &&
                      navigate(
                        location.pathname === '/event-history' && row.status === 'Pending'
                          ? `/requests/edit/${row.id}`
                          : `/requests/view/${row.id}`
                      )
                    }
                  >
                    <StyledTableCellLeftAlign>{row.status}</StyledTableCellLeftAlign>
                    <StyledTableCellLeftAlign>{formatDate(row.start)}</StyledTableCellLeftAlign>
                    <StyledTableCell>{formatNumberWithDecimal(row.hours)}</StyledTableCell>
                    {!sm && (
                      <StyledTableCell>
                        <ReusableButton
                          aria-label='View request'
                          size='small'
                          style={{ width: '4rem' }}
                          href={`/requests/view/${row.id}`}
                        >
                          View
                        </ReusableButton>
                      </StyledTableCell>
                    )}
                  </TableRow>
                ) : null
              )
            ) : (
              <TableRow>
                <StyledTableCell colSpan={4}>
                  No history of {capitalizeRequestType(type)} events scheduled.
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TabTable;
