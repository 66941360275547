import { ButtonProps, Button } from '@mui/material';
import { useTheme } from '@mui/material';

const ReusableButton = ({ children, ...props }: ButtonProps) => {
  const theme = useTheme();
  const { caxyGreen, caxyNavy } = theme.colors;

  const defaultButtonStyles = {
    width: '16rem',
    border: '1px solid',
    borderColor: caxyGreen,
    backgroundColor: caxyGreen,
    color: 'white',
    '&:hover': {
      border: '1px solid',
      borderColor: caxyNavy,
      color: caxyNavy,
    },
  };

  // Allows you to pass your own styles as an sx if you don't want the default

  const styles = props?.sx && Object.keys(props.sx).length > 0 ? props.sx : defaultButtonStyles;

  return (
    <Button sx={{ ...styles }} {...props}>
      {children}
    </Button>
  );
};
export default ReusableButton;
