import { Box, Typography } from '@mui/material';
import frogIcon from '../../assets/images/frog-pixel-small.png';
import ReusableButton from '../../components/buttons/ReusableButton';
import { useAuth0 } from '@auth0/auth0-react';

const Error500Page = () => {
  const { isAuthenticated } = useAuth0();
  return (
    <>
      <Box
        sx={{
          display: { xs: 'block', sm: 'flex' },
          alignItems: 'center',
          justifyContent: 'center',
          width: { xs: '100vw', sm: '600px' },
          marginTop: { sm: '5rem' },
          marginBottom: '1rem',
        }}
      >
        <Box
          sx={{
            width: { xs: '100%', sm: '50%' },
            marginBottom: { sm: '5.5rem' },
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img alt='frog icon' src={frogIcon}></img>
        </Box>
        <Box
          sx={{
            width: { xs: '100%', sm: '50%' },
            textAlign: 'center',
            paddingLeft: { xs: 0, sm: '1rem' },
            paddingRight: { xs: 0, sm: '0.9rem' },
            display: 'flex',
            flexDirection: 'column',
            gap: '2rem',
            alignItems: 'center',
          }}
        >
          <Typography sx={{ fontSize: { xs: '8rem', sm: '9rem' } }}>500</Typography>
          <Typography sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem' }, textAlign: 'left' }}>
            Dogs woof in English.
            <br />
            Frogs Caxy in Greek.
            <br />
            We&apos;ve hit a tiny hiccup,
            <br />
            but the future&apos;s not bleak.
          </Typography>
          <ReusableButton
            aria-label='Navigate to homepage'
            href={isAuthenticated ? '/dashboard' : '/login'}
          >
            Hop Back Home
          </ReusableButton>
        </Box>
      </Box>
    </>
  );
};

export default Error500Page;
