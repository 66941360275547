import { Box, Stack, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { DateTime } from 'luxon';
import { GET_ALL_USERS } from '../../graphql/users/queries/getAllUsers';
import { Link } from 'react-router-dom';
import { Role } from '../../enums/role';
import formatDate from '../../utils/FormatDate';
import Spinner from '../../components/Spinner';
import { StyledTableCell, StyledTableHeader } from '../User/Events/PendingEvents';
import { formatNumberWithDecimal } from '../../helpers/formattingAccruedBalance';
import { SortByInterface } from '../../helpers/sorting';
import SortArrow from '../../components/Tables/SortArrow';
import { handleSort } from '../../helpers/handleSort';

export interface GetAllUsers {
  id: number;
  username: string;
  email: string;
  role: Role;
  startDate: string;
  ptoRate: number;
  flexDays: number;
  wfhDays: number;
  emailSubscriptions: string;
  ptoHours: number;
  displayName: string;
  auth0Id: string | null;
  ptoHoursUsed: number;
  unpaidHours: number;
  exceptionDays: number;
  percentRemaining: number;
}

export interface AdminPageColumnData {
  id: number;
  user: string;
  ptoHoursRemaining: number;
  flexDaysRemaining: number;
  wfhDaysRemaining: number;
  exceptionTimeRemaining: number;
  hireDate: string;
  daysUntilAnniversary: number;
}

interface ColumnData {
  dataKey: keyof AdminPageColumnData;
  label: string;
  numeric?: boolean;
}

const columns: ColumnData[] = [
  {
    label: 'User',
    dataKey: 'user',
  },
  {
    label: 'PTO Hours Remaining',
    dataKey: 'ptoHoursRemaining',
    numeric: true,
  },
  {
    label: 'Flex Days Remaining',
    dataKey: 'flexDaysRemaining',
    numeric: true,
  },
  {
    label: 'WFH Days Remaining',
    dataKey: 'wfhDaysRemaining',
    numeric: true,
  },
  {
    label: 'Exception Time Remaining',
    dataKey: 'exceptionTimeRemaining',
    numeric: true,
  },
  {
    label: 'Days Until Anniversary',
    dataKey: 'daysUntilAnniversary',
  },
  {
    label: 'Hire Date',
    dataKey: 'hireDate',
  },
];

export const columnDataKeys = columns.map((column) => column.dataKey);

const createData = (
  id: number,
  user: string,
  ptoHoursRemaining: number,
  flexDaysRemaining: number,
  wfhDaysRemaining: number,
  exceptionTimeRemaining: number,
  hireDate: string,
  daysUntilAnniversary: number
): AdminPageColumnData => {
  return {
    id,
    user,
    ptoHoursRemaining,
    flexDaysRemaining,
    wfhDaysRemaining,
    exceptionTimeRemaining,
    hireDate,
    daysUntilAnniversary,
  };
};

const AdminUserPage = () => {
  const [sortConfig, setSortConfig] = useState<SortByInterface>({ column: null, direction: 'ASC' });
  const { data, loading, error } = useQuery(GET_ALL_USERS);

  const manufacturedData: AdminPageColumnData[] = useMemo(() => {
    if (!data) return [];

    return data.users?.map((data: GetAllUsers) => {
      const givenDate = DateTime.fromISO(data.startDate);
      const today = DateTime.now().startOf('day');

      let nextAnniversary = givenDate.set({ year: today.year });
      if (nextAnniversary < today) {
        nextAnniversary = nextAnniversary.plus({ years: 1 });
      }

      const daysUntilAnniversary = Math.floor(nextAnniversary.diff(today, 'days').days);

      return createData(
        data.id,
        data.username,
        parseFloat(formatNumberWithDecimal(data.ptoHours)),
        data.flexDays,
        data.wfhDays,
        data.exceptionDays,
        data.startDate,
        daysUntilAnniversary
      );
    });
  }, [data]);

  const onSort = useCallback(
    (columnName: string) => {
      const newDirection = handleSort(columnName, sortConfig);
      setSortConfig({ column: columnName, direction: newDirection });
    },
    [sortConfig, setSortConfig]
  );

  const { column, direction } = sortConfig;

  const sortedData = useMemo(() => {
    if (!manufacturedData.length || !column) return manufacturedData;

    // We know the sortConfig.column is of the correct type because it is sourced from column.dataKey, but need to keep TypeScript happy since the original shape of sortConfig.column is string
    const sortColumn: keyof AdminPageColumnData = column as keyof AdminPageColumnData;

    return [...manufacturedData].sort((a, b) => {
      const isReversed = direction === 'DESC' ? 1 : -1;

      if (typeof a[sortColumn] === 'string' && typeof b[sortColumn] === 'string') {
        return isReversed * String(a[sortColumn]).localeCompare(String(b[sortColumn]));
      }

      if (typeof a[sortColumn] === 'number' && typeof b[sortColumn] === 'number') {
        return isReversed * (Number(a[sortColumn]) - Number(b[sortColumn]));
      }

      return 0;
    });
  }, [manufacturedData, column, direction]);

  if (loading) return <Spinner />;
  if (error) return <p>Error: {error.message}</p>;

  const tableHeader = () => {
    return (
      <TableRow>
        {columns.map((singleColumn) => (
          <StyledTableHeader
            key={singleColumn.dataKey}
            variant='head'
            align='left'
            sx={{
              width: 'calc(100% / 6)',
            }}
            onClick={() => onSort(singleColumn.dataKey)}
            sortConfig={sortConfig}
            selected={column === singleColumn.dataKey}
          >
            <Stack direction='row'>
              {singleColumn.label}
              <SortArrow sortConfig={sortConfig} />
            </Stack>
          </StyledTableHeader>
        ))}
      </TableRow>
    );
  };

  return (
    <Box
      sx={{
        width: '90%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        margin: '2rem',
        marginBottom: '2rem',
        boxShadow: '1rem 1rem 2rem #00000033',
        borderRadius: '0.3rem',
        paddingX: '1rem',
        '@media (min-width: 20rem)': {
          width: '17.7rem',
        },
        '@media (min-width: 25rem)': {
          width: '23rem',
        },
        '@media (min-width: 33.125rem)': {
          width: '28.5rem',
        },
        '@media (min-width: 37.5rem)': {
          width: '35rem',
        },
        '@media (min-width: 48.438rem)': {
          width: '43rem',
        },
        '@media (min-width: 56.25rem)': {
          width: '50rem',
        },
        '@media (min-width: 62.5rem)': {
          width: '65rem',
        },
      }}
    >
      <TableContainer
        sx={{
          overflow: 'auto',
          maxHeight: '100%',
        }}
      >
        <Table stickyHeader>
          <TableHead>{tableHeader()}</TableHead>
          <TableBody>
            {sortedData.map((row, index) => (
              <TableRow key={index}>
                <StyledTableCell
                  sx={{
                    maxWidth: '7rem',
                    overflowX: 'scroll',
                    '&::-webkit-scrollbar': {
                      display: 'none',
                    },
                    scrollbarWidth: 'none',
                  }}
                >
                  <Link
                    to={`/users/${row.id}/view`}
                    style={{ textDecoration: 'none', color: '#43b458', fontWeight: 'bold' }}
                  >
                    {row.user}
                  </Link>
                </StyledTableCell>
                <StyledTableCell>{row.ptoHoursRemaining}</StyledTableCell>
                <StyledTableCell>{row.flexDaysRemaining}</StyledTableCell>
                <StyledTableCell>{row.wfhDaysRemaining}</StyledTableCell>
                <StyledTableCell>{row.exceptionTimeRemaining}</StyledTableCell>
                <StyledTableCell>{row.daysUntilAnniversary}</StyledTableCell>
                <StyledTableCell sx={{ width: '6.8rem', whiteSpace: 'nowrap' }}>
                  {formatDate(row.hireDate)}
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AdminUserPage;
