import { gql } from '@apollo/client';

export const ADD_AUTH_ID = gql`
  mutation addAuthId($input: AddUserAuthInput!) {
    addAuthId(addUserAuthInput: $input) {
      email
      emailSubscriptions
      flexDays
      id
      ptoHours
      ptoRate
      role
      startDate
      username
      wfhDays
    }
  }
`;
