import { gql } from '@apollo/client';

export const BULK_DELETE_EVENTS = gql`
  mutation bulkDeleteEvent($groupId: String!) {
    bulkDeleteRequests(groupId: $groupId) {
      id
      status
      start
      compensationType
      end
      statusReason
      user {
        id
        username
        ptoHours
        wfhDays
        flexDays
        exceptionDays
        role
        unpaidHours
        email
      }
      eventType {
        name
        code
      }
    }
  }
`;
