import { ApolloClient, ApolloProvider, from, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useAuth0 } from '@auth0/auth0-react';
import { ReactNode, useMemo } from 'react';

export const cache = new InMemoryCache();

const AuthorizedApolloProvider = ({ children }: { children: ReactNode }) => {
  const { getAccessTokenSilently } = useAuth0();

  const httpLink = useMemo(
    () =>
      new HttpLink({
        uri: `${process.env.REACT_APP_API_URL}/graphql`,
      }),
    []
  );

  const authLink = useMemo(
    () =>
      setContext(async (_, { headers }) => {
        try {
          const token = await getAccessTokenSilently();

          // return the headers to the context so httpLink can read them
          return {
            headers: {
              ...headers,
              ...(token ? { authorization: `Bearer ${token}` } : {}),
            },
          };
        } catch {
          return headers;
        }
      }),
    [getAccessTokenSilently]
  );

  const link = from([authLink, httpLink]);

  const client = useMemo(
    () =>
      new ApolloClient({
        link,
        cache,
      }),
    [link]
  );

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default AuthorizedApolloProvider;
