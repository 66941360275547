import { FC, ReactNode } from 'react';
import { Dialog, Box, DialogContent, DialogActions, Typography } from '@mui/material';

interface BaseModalProps {
  open: boolean;
  title?: string;
  content: ReactNode;
  actions: ReactNode;
}

// created a base modal component to be the backbone for all other modals since they all roughly shared the same styling
const BaseModal: FC<BaseModalProps> = ({ open, title, content, actions }) => {
  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          width: '23rem',
          bgcolor: '#efefef',
          borderRadius: 2,
          margin: 2,
          overflow: 'hidden',
        },
      }}
      BackdropProps={{
        sx: {
          backdropFilter: 'blur(8px)',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <Box
        onClick={(e) => e.stopPropagation()}
        sx={{ margin: '0 auto', paddingBottom: '1rem', width: '16rem' }}
      >
        {title && (
          <Typography
            sx={{
              color: '#43b458',
              textAlign: 'left',
              fontWeight: 'bold',
              fontSize: '1.5rem',
              paddingBottom: '0.313rem',
              paddingLeft: 1,
              paddingTop: 1,
            }}
          >
            {title}
          </Typography>
        )}
        <DialogContent sx={{ width: '100%', paddingLeft: 0, paddingTop: 0, paddingBottom: 1 }}>
          {content}
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            padding: 0,
          }}
        >
          {actions}
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default BaseModal;
