import { gql } from '@apollo/client';

export const GET_HOLIDAYS = gql`
  query getAllHolidays {
    holidays {
      date
      name
    }
  }
`;
