import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const MainHeader = styled(Typography)`
  font-weight: 600;
  font-size: 1.8rem;
  margin-top: 1.5rem;
`;

const SubHeader = styled(Typography)`
  font-weight: 600;
  font-size: 1.4rem;
  margin-top: 1.5rem;
`;

const SectionHeader = styled(Typography)`
  font-weight: 600;
  margin-top: 1.5rem;
`;

const Body = styled(Typography)`
  line-height: 1.6rem;
  margin-top: 0.5rem;
  margin-bottom: 1.25rem;
`;

const TimeManagementsBenefitsText = () => {
  return (
    <Box sx={{ marginY: '1rem', position: 'relative' }}>
      <MainHeader>Time Management Benefits</MainHeader>

      <SectionHeader>Flex / WFH Benefits</SectionHeader>
      <Body>
        FLEX and Work From Home/Remote Time is given annually to allow employees to take care of
        personal items such as being home for the plumber, etc. These benefits reset on January 1st
        each year - they do not carry over to future benefit years.
      </Body>

      <Body>Folks are optionally remote on Mondays and Fridays.</Body>

      <SectionHeader>Paid Personal Time Off</SectionHeader>

      <Body>
        15 paid days per benefit year, to be used as desired (sick days and personal days are
        included). Paid days accrue over your first 12 months at a rate of 1.25 days per month
        beginning at your start date, and then reset annually with all days available for scheduling
        beginning of each hire date anniversary. After 3 years, 20 PTO days are available.
      </Body>

      <Body>
        <b>Note:</b> Our Benefits Management system may display or allow you to use more hours than
        you have actually accrued. This is purely for convenience and planning in good faith. In the
        event of voluntary or involuntary termination, only the PTO hours you’ve actually accrued at
        the rate above (1.25 days per month worked in an annual cycle) would be paid out on
        departure. These benefits reset on your anniversary each year - they do not carry over to
        future benefit years.
      </Body>

      <SubHeader>Exception Time Off</SubHeader>
      <Body>Specially approved time off granted for a few very specific instances. </Body>

      <Body>
        Exception time can be requested separately from your regular paid personal time off for the
        following reasons:
      </Body>

      <SectionHeader>Bereavement Leave</SectionHeader>
      <Body>
        Up to three days paid leave in the event of the death of an immediate family member (defined
        as a spouse, child, parent, sibling or comparable step relation, grandparent, father-in-law,
        mother-in-law, son-in-law, daughter-in-law, or legal guardian). At the discretion of Caxy,
        certain chosen family may qualify under this policy. Reach out to Operations/Management to
        discuss.{' '}
      </Body>
      <Body>
        Up to 1 day paid leave for extended family members (defined as aunt, uncle, cousin or
        comparable in-law relations).{' '}
      </Body>
      <Body>
        Caxy reserves the right to approve additional leave for non-traditional bereavement of pets,
        friends, and other companions on a case by case basis.{' '}
      </Body>
      <Body>Additional time off for bereavement leave is addressed on a case by case basis.</Body>

      <SectionHeader>Jury Duty</SectionHeader>
      <Body>
        Team members are granted 1 day leave for jury duty.{' '}
        <b>Exceptions for additional time is made on a case by case basis.</b>
      </Body>

      <SectionHeader>Religious/Ethno-Religious Observance</SectionHeader>
      <Body>
        Team members are able to take time away for religious and ethno-religious observances. No
        team member is punished or excluded from benefit as a result of these observances.
      </Body>
      <Body>
        Up to 5 days of exception time may be granted for observance after all PTO has exhausted.
        Exceptions can be made on a case by case basis.
      </Body>
      <Body>
        Holidays that are already on the Caxy calendar are not eligible for this benefit.{' '}
      </Body>
      <Body>
        Family traditions that are not expressly part of the typical religious or ethno-religious
        observances are not eligible for this benefit.{' '}
      </Body>
      <Body>
        At the sole discretion of Caxy, we may reserve the right to approve none, some, or all of
        exception time requests for additional time off as a result of religious observances.
        Approvals or rejections of previous or current years do not dictate future outcomes.
      </Body>

      <SectionHeader>Additional Benefits</SectionHeader>

      <Body>
        Additional time off at the end of the year for folks that are in our 6ers group (people that
        have been employed at least 6 years).
      </Body>

      <Body>
        <b>PLEASE NOTE:</b> Same day requests require a PHONE CALL to Hannah or Mike for approval.
        Time is not approved until you receive a confirmation.
      </Body>

      <Body>
        See full{' '}
        <Link to='https://docs.google.com/document/d/1dkDqqQdZplpS_ja_0cGwP5hO2e0QYo8qW6sJv-Q-rro/edit#heading=h.fj0iohjo7x4z'>
          Time Off, WFH, Flex Policy
        </Link>{' '}
        here.
      </Body>
    </Box>
  );
};

export default TimeManagementsBenefitsText;
