import { MouseEvent, useState } from 'react';
import { Button, ClickAwayListener, Menu, Typography } from '@mui/material';
import { MenuItem as CustomMenuItem } from '../navigation/MenuItem';

interface ReportsDropdownProps {
  navClose: () => void;
}

const ReportsDropdown = ({ navClose }: ReportsDropdownProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavClose = () => {
    handleClose();
    navClose();
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <>
        <Button
          onClick={handleClick}
          variant='text'
          color='inherit'
          sx={{
            pl: { xs: '1rem', md: '0' },
            padding: { xs: 'auto', md: '0' },
            width: { xs: '100%', md: 'auto' },
            textAlign: 'start',
            borderRadius: '0',
            minWidth: { md: '0' },
          }}
        >
          <Typography sx={{ textTransform: 'none', width: { xs: '100%', md: 'auto' } }}>
            Reports
          </Typography>
        </Button>
        <Menu
          sx={{ ml: { xs: '-15px', md: 0 } }}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
        >
          <CustomMenuItem onClick={handleNavClose} url='/reports/pto-spend' linkText='PTO Spend' />
          <CustomMenuItem
            onClick={handleNavClose}
            url='/reports/total-benefits'
            linkText='Total Benefits Used'
          />
        </Menu>
      </>
    </ClickAwayListener>
  );
};

export default ReportsDropdown;
