import { gql } from '@apollo/client';

export const BULK_APPROVE_OR_DENY_DATE_EVENT = gql`
  mutation bulkApproveOrDenyDateEvent($input: BulkApproveDenyInput!) {
    bulkApproveOrDeny(bulkApproveOrDenyInput: $input) {
      id
      status
      start
      compensationType
      end
      statusReason
      user {
        id
        username
        ptoHours
        wfhDays
        flexDays
        exceptionDays
        role
        unpaidHours
      }
      eventType {
        name
        code
      }
    }
  }
`;
