import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { Alert, Box, InputLabel, SelectChangeEvent, TextField } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ReusableButton from '../buttons/ReusableButton';
import FormSubmissionModal from '../modal/FormSubmissionModalComponent';
import TypeDropdownMenu from './TypeDropdownMenu/TypeDropdownMenu';
import DateAndTime from './DateAndTime/DateAndTime';
import ReasonRequested from './ReasonRequested/ReasonRequested';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_DATE_EVENT } from '../../graphql/dateEvents/mutations/createDateEvent';
import { useCurrentUser } from '../../../src/providers/CurrentUserContextProvider';
import Spinner from '../Spinner';
import { GET_EVENT_TYPE_BY_CODE } from '../../graphql/eventTypes/queries/getEventByCode';
import { v4 as uuidv4 } from 'uuid';
import { DateEvent } from '../../routes/User/Requests/EditRequest';
import { UPDATE_DATE_EVENT } from '../../graphql/dateEvents/mutations/updateDateEvent';
import UsersDropdown from '../Dropdowns/UsersDropdown';
import RequestStatusDropdown from '../Dropdowns/RequestStatusDropdown';
import { RequestStatus } from '../../enums/RequestStatus';
import { UPDATE_USER } from '../../graphql/users/mutations/updateUser';
import { GET_DATE_EVENT_BY_ID } from '../../graphql/dateEvents/queries/getDateEventById';
import { cache } from '../../providers/AuthorizedApolloProvider';
import { BULK_CREATE_DATE_EVENT } from '../../graphql/dateEvents/mutations/bulkCreateDateEvent';
import { GET_HOLIDAYS } from '../../graphql/holidays/getHolidays';

interface ReusableFormComponentProps {
  currentPage: string;
  existingEvent?: DateEvent;
  resetState?: () => void;
  disabled?: boolean;
}

interface Holiday {
  date: string;
  name: string;
}

const ReusableFormComponent: FC<ReusableFormComponentProps> = ({
  currentPage,
  existingEvent,
  resetState,
  disabled,
}) => {
  const parseDateTime = useCallback((dateType: 'start' | 'end', isoString?: string) => {
    const dateTime = isoString ? DateTime.fromISO(isoString) : DateTime.local();
    return {
      date: dateTime.toISODate()!,
      time: isoString ? dateTime.toFormat('HH:mm') : dateType === 'start' ? '09:00' : '17:00',
    };
  }, []);

  const { currentUserData, isAdmin } = useCurrentUser();
  const currentUserId = currentUserData?.userByEmail.id;
  const start = parseDateTime('start', existingEvent?.start);
  const end = parseDateTime('end', existingEvent?.end);

  const navigate = useNavigate();
  const [eventType, setEventType] = useState<string>(currentPage);
  const [startDate, setStartDate] = useState<DateTime>(DateTime.fromISO(start.date));
  const [endDate, setEndDate] = useState<DateTime>(DateTime.fromISO(end.date));
  const [startTime, setStartTime] = useState<string>(start.time);
  const [endTime, setEndTime] = useState<string>(end.time);
  const [enteredText, setEnteredText] = useState<string>(existingEvent?.reasonRequested ?? '');
  const [isReasonEmpty, setIsReasonEmpty] = useState<boolean>(
    existingEvent ? !!existingEvent.reasonRequested?.length : true
  );
  const [isValidDateEntry, setIsValidDateEntry] = useState<boolean>(true);
  const [isTimeValid, setIsTimeValid] = useState<boolean>(true);
  const [isMinuteSelectionValid, setIsMinuteSelectionValid] = useState<boolean>(true);
  const [isMultiDayRequestValid, setIsMultiDayRequestValid] = useState<boolean>(true);
  const [isMultiDayRequestInEdit, setIsMultiDayRequestInEdit] = useState<boolean>(true);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<number | undefined | string>(currentUserId);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(
    existingEvent?.status ?? RequestStatus.Pending
  );
  const [statusReason, setStatusReason] = useState<string>(existingEvent?.statusReason ?? '');
  const location = useLocation();
  const isEditPage = location.pathname.split('/').includes('edit');

  const { data } = useQuery(GET_EVENT_TYPE_BY_CODE, {
    variables: {
      code: existingEvent ? existingEvent.eventType.code : currentPage,
    },
  });

  const { data: holidays } = useQuery(GET_HOLIDAYS);

  // Function to check if a date is a holiday
  const isHoliday = (inputDate: DateTime): boolean => {
    return holidays?.holidays.some((holiday: Holiday) =>
      DateTime.fromISO(holiday.date).hasSame(DateTime.fromISO(inputDate.toString()), 'day')
    );
  };

  const generateUUID = () => {
    return uuidv4();
  };

  useEffect(() => {
    if (isSuccessful) {
      setShowSpinner(true);

      const timer = setTimeout(() => {
        setShowSpinner(false);
        setShowModal(true);
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [isSuccessful]);

  const [createDateEvent] = useMutation(CREATE_DATE_EVENT, {
    onCompleted: () => {
      cache.evict({ fieldName: 'userByEmail' });
      cache.evict({ fieldName: 'users' });
    },
  });
  const [updateDateEvent] = useMutation(UPDATE_DATE_EVENT, {
    onCompleted: () => {
      cache.evict({ fieldName: 'userByEmail' });
      cache.evict({ fieldName: 'users' });
    },
  });
  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      cache.evict({ fieldName: 'dateEvents' });
      cache.gc();
    },
  });
  const [bulkCreateDateEvent] = useMutation(BULK_CREATE_DATE_EVENT, {
    onCompleted: () => {
      cache.evict({ fieldName: 'userByEmail' });
      cache.evict({ fieldName: 'users' });
    },
  });

  const { id } = useParams<{ id: string | undefined }>();
  const { data: dateEvent } = useQuery(GET_DATE_EVENT_BY_ID, {
    variables: {
      id: Number(id),
    },
  });

  const handleSelectUser = (userId: number) => {
    setSelectedUserId(userId);
  };

  const handleSelectStatus = (status: RequestStatus) => {
    setRequestStatus(status);
  };

  const handleChangeStatusReason = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStatusReason(event.target.value);
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    setEventType(e.target.value);
    if (!existingEvent) {
      navigate(`/requests/${e.target.value}`);
    }
  };

  const handleReasonChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsReasonEmpty(true);
    setEnteredText(e.target.value);
  };

  const handleStartDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    setStartDate(DateTime.fromISO(e.target.value));
    setEndDate(DateTime.fromISO(e.target.value));
    setIsMultiDayRequestInEdit(true);
  };

  const handleEndDateChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setEndDate(DateTime.fromISO(e.target.value));
    setIsMultiDayRequestInEdit(true);
  };
  const handleStartTimeChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setStartTime(e.target.value);
    setIsMultiDayRequestValid(true);
    setIsMinuteSelectionValid(true);
  };

  const handleEndTimeChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setEndTime(e.target.value);
    setIsMultiDayRequestValid(true);
    setIsMinuteSelectionValid(true);
  };
  const handleReturnDashboard = () => navigate('/dashboard');
  const handleClose = () => {
    setIsSuccessful(false);
    resetState && resetState();
    if (isEditPage) navigate(`/requests/${eventType}`);
  };

  const isWeekend = (date: DateTime) => {
    const dayOfWeek = date.weekday;
    return dayOfWeek === 6 || dayOfWeek === 7;
  };

  const getDateRange = (startDate: DateTime, endDate: DateTime): DateTime[] => {
    const dates: DateTime[] = [];
    let currentDate = startDate;

    while (currentDate <= endDate) {
      if (!isWeekend(currentDate) && !isHoliday(currentDate)) {
        dates.push(currentDate);
      }
      currentDate = currentDate.plus({ days: 1 });
    }
    return dates;
  };

  const startOfDay = startDate.startOf('day');
  const endOfDay = endDate.startOf('day');
  const difference = endOfDay.diff(startOfDay, 'days').as('days');

  // this function - calculateHours - is called to return the number of hours in a given request. It is called during a successful form submission
  const calculateHours = (startTime: string, endTime: string): number => {
    const timeToHours = (timeStr: string): number => {
      const [hours, minutes] = timeStr.split(':').map(Number);
      return hours + minutes / 60;
    };
    const startHours = timeToHours(startTime);
    const endHours = timeToHours(endTime);
    return endHours - startHours;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    let isFormValid = true;

    const startISO = startDate.toISO();
    const endISO = endDate.toISO();

    const startDateAmericanFormat = startDate.toFormat('MM/dd/yyyy');
    const endDateAmericanFormat = endDate.toFormat('MM/dd/yyyy');

    let startDateTime: DateTime | null = null;
    let endDateTime: DateTime | null = null;

    if (endISO && startISO) {
      startDateTime = DateTime.fromISO(startISO);
      endDateTime = DateTime.fromISO(endISO);
      const validDates = getDateRange(startDateTime, endDateTime);
      const hours = calculateHours(startTime, endTime);

      const startTimeMinutes = startTime.split(':')[1];
      const endTimeMinutes = endTime.split(':')[1];

      const isStartTimeValid = startTimeMinutes == '00' || startTimeMinutes == '30';
      const isEndTimeValid = endTimeMinutes == '00' || endTimeMinutes == '30';

      const isMinuteSelectionValid = isStartTimeValid && isEndTimeValid;

      if (endDateTime < startDateTime) {
        setIsValidDateEntry(false);
        isFormValid = false;
      } else if (startDateAmericanFormat === endDateAmericanFormat && endTime <= startTime) {
        setIsValidDateEntry(false);
        isFormValid = false;
      } else if (hours < 0) {
        setIsTimeValid(false); // this block will be hit for overnight requests where calculated hours result in a negative number
        isFormValid = false;
      } else if (!isMinuteSelectionValid) {
        setIsMinuteSelectionValid(false);
        isFormValid = false;
      } else if (validDates.length > 1 && hours < 8) {
        setIsMultiDayRequestValid(false); // this block will be hit for multiday requests where last day has partial hours submitted for it
        isFormValid = false;
      } else {
        setIsValidDateEntry(true);
      }
    } else {
      setIsValidDateEntry(true);
    }

    if (!enteredText) {
      setIsReasonEmpty(false);
      isFormValid = false;
    }

    if (isFormValid && startDateTime && endDateTime) {
      const validDates = getDateRange(startDateTime, endDateTime);
      let newGroupId: string | null = null;
      if (difference > 0) newGroupId = generateUUID();

      if (isEditPage && validDates.length > 1) {
        setIsMultiDayRequestInEdit(false);
        return;
      }

      validDates.forEach((date, index) => {
        const isFirstDate = index === 0;
        const isLastDate = index === validDates.length - 1;
        const currentPTOHours = currentUserData?.userByEmail?.ptoHours ?? 0;
        const currentUnpaidHours = currentUserData?.userByEmail?.unpaidHours ?? 0;
        const initialHours = dateEvent?.dateEventById.hours;
        const hours = calculateHours(startTime, endTime);

        if (existingEvent) {
          // within this block we are updating an existing event. To properly reflect accurate PTO hours for a user, we must first obtain what those hours are, then add back what was initially in the request before subtracting the hours in the new request.
          updateDateEvent({
            variables: {
              input: {
                id: existingEvent.id,
                reasonRequested: enteredText,
                start: `${date.toFormat('yyyy-MM-dd')} ${startTime}`,
                end: `${date.toFormat('yyyy-MM-dd')} ${endTime}`,
                userId: selectedUserId ?? existingEvent.user.id,
                status: requestStatus,
                hours: Math.min(hours, 8),
                statusReason,
              },
            },
          });

          if (eventType === 'pto') {
            // this updates the user's ptoHours accurately
            updateUser({
              variables: {
                input: {
                  id: currentUserData?.userByEmail.id,
                  ptoHours:
                    requestStatus !== RequestStatus.Denied
                      ? currentPTOHours + initialHours - Math.min(hours, 8)
                      : currentPTOHours + initialHours,
                },
              },
            });
          } else if (eventType === 'unpaid' && typeof currentUnpaidHours === 'number') {
            updateUser({
              variables: {
                input: {
                  id: currentUserData?.userByEmail.id,
                  unpaidHours:
                    requestStatus !== RequestStatus.Denied
                      ? currentUnpaidHours - initialHours + Math.min(hours, 8)
                      : currentUnpaidHours - initialHours,
                },
              },
            });
          } else if (eventType === 'wfh' || eventType === 'flex' || eventType === 'exception') {
            // within this block of code, if the eventType is wfh or flex, then based on additional conditionals -- different things can happen
            const wfhDays = currentUserData?.userByEmail.wfhDays ?? 0;
            const flexDays = currentUserData?.userByEmail.flexDays ?? 0;
            const exceptionDays = currentUserData?.userByEmail.exceptionDays ?? 0;

            const initialRequestStatus = dateEvent?.dateEventById.status;

            // if the initial request status of the request was denied, perform the logic in the if block
            if (initialRequestStatus === RequestStatus.Denied) {
              updateUser({
                variables: {
                  input: {
                    id: currentUserData?.userByEmail.id,
                    wfhDays:
                      eventType === 'wfh' && requestStatus !== RequestStatus.Denied
                        ? wfhDays - 1
                        : wfhDays,
                    flexDays:
                      eventType === 'flex' && requestStatus !== RequestStatus.Denied
                        ? flexDays - 1
                        : flexDays,
                    exceptionDays:
                      eventType === 'exception' && requestStatus !== RequestStatus.Denied
                        ? exceptionDays - 1
                        : exceptionDays,
                  },
                },
              });
            } else {
              updateUser({
                variables: {
                  input: {
                    id: currentUserData?.userByEmail.id,
                    wfhDays:
                      eventType === 'wfh' && requestStatus === RequestStatus.Denied
                        ? wfhDays + 1
                        : wfhDays,
                    flexDays:
                      eventType === 'flex' && requestStatus === RequestStatus.Denied
                        ? flexDays + 1
                        : flexDays,
                    exceptionDays:
                      eventType === 'exception' && requestStatus === RequestStatus.Denied
                        ? exceptionDays + 1
                        : exceptionDays,
                  },
                },
              });
            }
          }
        } else {
          // Checks to see if the request was a bulk or singular request and calls function accordingly
          if (index === 0) {
            if (newGroupId) {
              const dateEvents = validDates.map((date) => {
                return {
                  userId: selectedUserId,
                  eventTypeId: data?.eventType.id,
                  reasonRequested: enteredText,
                  start: `${date.toFormat('yyyy-MM-dd')} ${isFirstDate ? `${startTime}` : '09:00'}`,
                  end: `${date.toFormat('yyyy-MM-dd')} ${isLastDate ? `${endTime}` : '17:00'}`,
                  status: requestStatus,
                  groupId: newGroupId,
                };
              });

              bulkCreateDateEvent({
                variables: {
                  input: {
                    events: dateEvents,
                  },
                },
              });
            } else {
              createDateEvent({
                variables: {
                  input: {
                    userId: selectedUserId,
                    eventTypeId: data?.eventType.id,
                    reasonRequested: enteredText,
                    start: `${date.toFormat('yyyy-MM-dd')} ${isFirstDate ? `${startTime}` : '09:00'}`,
                    end: `${date.toFormat('yyyy-MM-dd')} ${isLastDate ? `${endTime}` : '17:00'}`,
                    groupId: newGroupId,
                    status: requestStatus,
                  },
                },
              });
            }
          }

          // if eventType is 'pto' then do the logic in the if condition
          if (data?.eventType.code === 'pto') {
            const currentPTOHours = currentUserData?.userByEmail.ptoHours ?? 0;
            // this calculates the number of hours in a given request
            const hours = calculateHours(startTime, endTime);
            // here we are updating a user to have their ptoHours be updated once a successful pto request is made. Hours should be properly subtracted with this
            updateUser({
              variables: {
                input: {
                  id: selectedUserId,
                  ptoHours: currentPTOHours - Math.min(hours, 8) * validDates.length,
                },
              },
            });
          } else if (data?.eventType.code === 'unpaid') {
            const currentPTOHours = currentUserData?.userByEmail.unpaidHours ?? 0;
            const hours = calculateHours(startTime, endTime);
            updateUser({
              variables: {
                input: {
                  id: selectedUserId,
                  unpaidHours: currentPTOHours + Math.min(hours, 8) * validDates.length,
                },
              },
            });
          } else if (
            data?.eventType.code === 'flex' ||
            data?.eventType.code === 'wfh' ||
            data?.eventType.code === 'exception'
          ) {
            const user = currentUserData?.userByEmail;

            if (user) {
              // initialize default values to avoid errors during calculations
              const currentWFH = user.wfhDays ?? null;
              const currentFlex = user.flexDays ?? null;
              const currentException = user.exceptionDays ?? null;

              // checking if the current values are valid before performing any operations
              const wfhDays =
                data?.eventType.code === 'wfh' && currentWFH != null
                  ? currentWFH - validDates.length
                  : currentWFH;
              const flexDays =
                data?.eventType.code === 'flex' && currentFlex != null
                  ? currentFlex - validDates.length
                  : currentFlex;
              const exceptionDays =
                data?.eventType.code === 'exception' && currentException != null
                  ? currentException - validDates.length
                  : currentException;

              //here we are updating a user to have either their wfh or flex days updated once a successful wfh or flex request is made, respectively. Number should be properly subtracted with this
              updateUser({
                variables: {
                  input: {
                    id: selectedUserId,
                    wfhDays: wfhDays !== null ? wfhDays : user.wfhDays,
                    flexDays: flexDays !== null ? flexDays : user.flexDays,
                    exceptionDays: exceptionDays !== null ? exceptionDays : user.exceptionDays,
                  },
                },
              });
            }
          }
        }
      });
      setIsSuccessful(true);
    }
  };

  return (
    <>
      {showSpinner ? (
        <Spinner />
      ) : (
        <Box
          component='form'
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            margin: '0 auto',
            width: '80vw',
            maxWidth: 600,
            boxShadow: '1rem 1rem 2rem #00000033',
            borderRadius: '0.5rem',
            padding: '1rem',
            marginBottom: '2rem',
            backgroundColor: 'white',
          }}
          onSubmit={handleSubmit}
        >
          <TypeDropdownMenu
            selectedValue={eventType}
            handleSelectChange={handleSelectChange}
            disabled={disabled}
          />
          <DateAndTime
            startDate={startDate}
            startTime={startTime}
            handleStartDateChange={handleStartDateChange}
            handleStartTimeChange={handleStartTimeChange}
            endDate={endDate}
            endTime={endTime}
            handleEndDateChange={handleEndDateChange}
            handleEndTimeChange={handleEndTimeChange}
            disabled={disabled}
          />
          {isValidDateEntry ? (
            ''
          ) : (
            <Alert variant='filled' severity='error'>
              Please note, start date and time must be before end date and time.
            </Alert>
          )}
          {/* Here an alert will pop up if user puts in overnight request*/}
          {isTimeValid ? (
            ''
          ) : (
            <Alert variant='filled' severity='error'>
              Please check your inputs for times. Must be between 9am and 5pm.
            </Alert>
          )}
          {isMinuteSelectionValid ? (
            ''
          ) : (
            <Alert variant='filled' severity='error'>
              Please check your inputs for times. Must end in :00 or :30.
            </Alert>
          )}
          {/* Here an alert will pop up if multiday request is invalid */}
          {isMultiDayRequestValid ? (
            ''
          ) : (
            <Alert variant='filled' severity='error'>
              Please note, multiday requests must be full 8 hour days. Please correct timing inputs.
            </Alert>
          )}
          {isMultiDayRequestInEdit ? (
            ''
          ) : (
            <Alert variant='filled' severity='error'>
              Please note, single-day requests cannot be adjusted to span multiple days.
            </Alert>
          )}
          <ReasonRequested
            enteredText={enteredText}
            handleReasonChange={handleReasonChange}
            disabled={disabled}
          />
          {isReasonEmpty ? (
            ''
          ) : (
            <Alert variant='filled' severity='error'>
              Please fill out this field.
            </Alert>
          )}
          {isAdmin && (
            <>
              <InputLabel htmlFor='owner' sx={{ fontWeight: 'bold', color: 'black', mb: '-1rem' }}>
                Owner
              </InputLabel>
              <UsersDropdown
                selectedUserId={existingEvent ? existingEvent?.user.id : currentUserId}
                handleSelectUser={handleSelectUser}
              />
              <InputLabel htmlFor='status' sx={{ fontWeight: 'bold', color: 'black', mb: '-1rem' }}>
                Status
              </InputLabel>
              <RequestStatusDropdown
                initialStatus={existingEvent ? existingEvent.status : RequestStatus.Pending}
                handleSelectStatus={handleSelectStatus}
              />
              <InputLabel
                htmlFor='status-reason'
                sx={{ fontWeight: 'bold', color: 'black', mb: '-1rem' }}
              >
                Status reason
              </InputLabel>
              <TextField
                id='status-reason'
                multiline
                value={statusReason}
                onChange={handleChangeStatusReason}
              />
            </>
          )}
          <ReusableButton
            aria-label='Submit form'
            style={{ width: '40%', alignSelf: 'left' }}
            type='submit'
            variant='outlined'
            disabled={disabled}
          >
            Submit
          </ReusableButton>
          {showModal ? (
            <FormSubmissionModal
              editPage={isEditPage}
              open={isSuccessful}
              onClose={handleClose}
              onReturnDashboard={handleReturnDashboard}
            />
          ) : (
            ''
          )}
        </Box>
      )}
    </>
  );
};

export default ReusableFormComponent;
