import { useQuery } from '@apollo/client';
import {
  Alert,
  Box,
  ClickAwayListener,
  IconButton,
  Stack,
  styled,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { GET_DATE_EVENTS_FILTERED } from '../../../graphql/dateEvents/queries/getDateEventsFiltered';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCurrentUser } from '../../../providers/CurrentUserContextProvider';
import { Event, StyledTableCell, StyledTableHeader } from '../Events/PendingEvents';
import formatDate from '../../../utils/FormatDate';
import { capitalizeRequestType } from '../../../utils/capitalizeRequestType';
import ReusableButton from '../../../components/buttons/ReusableButton';
import { Link, useNavigate } from 'react-router-dom';
import TimeUntilResets, { PTOHour } from '../../../components/userStats/TimeUntilResets';
import { ResponsiveButton } from '../../Dashboard';
import TimeManagementsBenefitsText from '../../../components/TimeManagementBenefitsText';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import Header from '../../../components/text/Header';
import { formatNumberWithDecimal } from '../../../helpers/formattingAccruedBalance';
import { DateTime } from 'luxon';
import Spinner from '../../../components/Spinner';
import { useDeleteRequest } from '../../../providers/DeleteRequestContextProvider';
import { SortByInterface } from '../../../helpers/sorting';
import SortArrow from '../../../components/Tables/SortArrow';

const ActionCell = styled(StyledTableCell)`
  @media (max-width: 525px) {
    display: none; /* Hide on small screens */
  }
`;

const useWindowWidth = () => {
  // setting state for width of window
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  // handling setting the windowWidth when screensize changes
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowWidth;
};

const RequestsPage = () => {
  const [now] = useState(
    DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toUTC()
  );
  const { currentUserData } = useCurrentUser();
  const {
    isDeleted,
    setIsDeleted,
    userDeleteMessage,
    setUserDeleteMessage,
    requestedUserEmail,
    setRequestedUserEmail,
    yourDeleteMessage,
    setYourDeleteMessage,
    setIsBulkDeleted,
  } = useDeleteRequest();
  const user = currentUserData?.userByEmail;
  const navigate = useNavigate();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.up('xs'));
  const [detailsExtended, setDetailsExtended] = useState<boolean>(false);
  // obtaining the window width and declaring where smaller screen changes should begin. At 525px.
  const windowWidth = useWindowWidth();
  const isUnder525 = windowWidth <= 525;
  const [sortConfig, setSortConfig] = useState<SortByInterface>({ column: null, direction: 'ASC' });

  const columns = [
    { label: 'Type', dataKey: 'eventType', width: '20%' },
    { label: 'Status', dataKey: 'status', width: '22%' },
    { label: 'Date', dataKey: 'date', width: '22%' },
    { label: 'Hours', dataKey: 'hours', width: '18%' },
    { label: 'Action', dataKey: 'action', width: '15%' },
  ];

  const tableHeader = () => {
    return (
      <TableRow>
        {columns.map((column) => {
          const isActionButton = column.label === 'Action';
          return (
            <StyledTableHeader
              key={column.dataKey}
              variant='head'
              align='left'
              sx={{
                width: column.width,
                ...(isActionButton && {
                  '@media (max-width: 525px)': {
                    display: 'none',
                  },
                }),
              }}
              onClick={isActionButton ? undefined : () => handleSort(column.dataKey)}
              selected={sortConfig.column === column.dataKey}
              sortConfig={sortConfig}
            >
              <Stack direction='row'>
                {column.label}
                {!isActionButton && <SortArrow sortConfig={sortConfig} />}
              </Stack>
            </StyledTableHeader>
          );
        })}
      </TableRow>
    );
  };

  const { data, loading } = useQuery(GET_DATE_EVENTS_FILTERED, {
    variables: {
      input: {
        filters: {
          afterDate: now,
          forUserId: currentUserData?.userByEmail.id,
        },
      },
    },
  });

  const events = useMemo(() => {
    return data?.dateEventsFiltered ?? [];
  }, [data]);

  const sortedEvents = useMemo(() => {
    if (!events.length || !sortConfig.column) return events;

    return [...events].sort((a, b) => {
      const isReversed = sortConfig.direction === 'DESC' ? 1 : -1;

      const secondarySort = new Date(a.start).getTime() - new Date(b.start).getTime();

      switch (sortConfig.column) {
        case 'eventType':
          return isReversed * a.eventType.code.localeCompare(b.eventType.code) || secondarySort;
        case 'status':
          return isReversed * a.status.localeCompare(b.status) || secondarySort;
        case 'date':
          return isReversed * secondarySort;
        case 'hours':
          return isReversed * (a.hours - b.hours) || secondarySort;
        default:
          return 0;
      }
    });
  }, [events, sortConfig]);

  const handleSort = useCallback(
    (columnName: string) => {
      const newDirection =
        sortConfig.column === columnName && sortConfig.direction === 'ASC' ? 'DESC' : 'ASC';
      setSortConfig({ column: columnName, direction: newDirection });
    },
    [sortConfig, setSortConfig]
  );

  const handleRowClick = (id: number) => {
    navigate(`/requests/view/${id}`);
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isDeleted) {
      timer = setTimeout(() => {
        setIsDeleted(false);
        setIsBulkDeleted(false);
        setUserDeleteMessage('');
        setYourDeleteMessage('');
        setRequestedUserEmail('');
      }, 2500);
    }
    return () => clearTimeout(timer);
  }, [
    isDeleted,
    setIsDeleted,
    setUserDeleteMessage,
    setYourDeleteMessage,
    setRequestedUserEmail,
    setIsBulkDeleted,
  ]);

  if (loading) return <Spinner />;

  const isCurrentUserTheOneWhoRequestedTheDateEvent =
    currentUserData?.userByEmail.email === requestedUserEmail;

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          marginTop: '1rem',
          marginX: '1rem',
          width: '44rem',
          '@media (min-width: 20rem)': {
            width: '18rem',
          },
          '@media (min-width: 22.5rem)': {
            width: '20rem',
          },
          '@media (min-width: 26.563rem)': {
            width: '24rem',
          },
          '@media (min-width: 32.875rem)': {
            width: '28rem',
          },
          '@media (min-width: 37.5rem)': {
            width: '34rem',
          },
          '@media (min-width: 56.25rem)': {
            width: '37rem',
          },
        }}
      >
        {isDeleted && (
          <ClickAwayListener
            onClickAway={() => {
              setIsDeleted(false);
              setIsBulkDeleted(false);
              setUserDeleteMessage('');
              setYourDeleteMessage('');
              setRequestedUserEmail('');
            }}
          >
            <Alert
              sx={{
                justifyContent: 'center',
                width: '17.5rem',
                margin: '0 auto',
                marginTop: '1rem',
              }}
              variant='filled'
              severity='error'
            >
              {isCurrentUserTheOneWhoRequestedTheDateEvent ? yourDeleteMessage : userDeleteMessage}
            </Alert>
          </ClickAwayListener>
        )}
        <Header>Your Upcoming Requests</Header>

        <TimeUntilResets variant='compact' />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '0.5rem',
            marginBottom: '0.5rem',
            boxShadow: '1rem 1rem 2rem #00000033',
            borderRadius: '0.3rem',
            overflow: 'hidden',
            paddingX: '0.25rem',
            backgroundColor: 'white',
          }}
        >
          {events && (
            <TableContainer
              sx={{
                overflowX: { xs: 'auto', sm: 'hidden' },
                overflowY: 'auto',
                maxHeight: '100%',
              }}
            >
              <Table stickyHeader>
                <TableHead>{tableHeader()}</TableHead>
                <TableBody>
                  {sortedEvents.map((event: Event, index: number) => (
                    <TableRow key={index} onClick={() => isUnder525 && handleRowClick(event.id)}>
                      <StyledTableCell sx={{ width: columns[0].width }}>
                        {capitalizeRequestType(event.eventType.code)}
                      </StyledTableCell>
                      <StyledTableCell sx={{ width: columns[1].width }}>
                        {event.status}
                      </StyledTableCell>
                      <StyledTableCell sx={{ width: columns[2].width }}>
                        {formatDate(event.start)}
                      </StyledTableCell>
                      <StyledTableCell sx={{ width: columns[3].width }}>
                        {formatNumberWithDecimal(event.hours)}
                      </StyledTableCell>
                      <ActionCell sx={{ textAlign: 'left', width: columns[4].width }}>
                        <ReusableButton
                          size='small'
                          style={{ width: '5rem' }}
                          href={`/requests/view/${event.id}`}
                        >
                          View
                        </ReusableButton>
                      </ActionCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>

        {!events.length && (
          <Typography
            sx={{
              marginBottom: '0.25rem',
              textAlign: 'left',
            }}
          >
            You have no upcoming requests. Would you like to{' '}
            <Link to={'/requests/pto'} style={{ color: 'black' }}>
              make a request?
            </Link>
          </Typography>
        )}

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 auto',
            marginTop: '1rem',
            gap: '0.75rem',
            width: '90%',
            flexDirection: 'row',
            '@media (max-width: 26.5rem)': {
              flexDirection: 'column',
            },
          }}
        >
          <ResponsiveButton style={{ width: '15rem' }} variant='outlined' href='/requests/pto'>
            Request Time Off
          </ResponsiveButton>
          <ResponsiveButton style={{ width: '15rem' }} variant='outlined' href='/event-history'>
            Event History
          </ResponsiveButton>
        </Box>

        {user && (
          <PTOHour style={{ paddingTop: '1rem', paddingBottom: '2rem', margin: 0 }}>
            *Note: PTO Hours are front-loaded for your convenience, but do accrue over time. Your
            actual accrued balance is <strong>{formatNumberWithDecimal(user.ptoHours)}</strong>{' '}
            hours.{' '}
            {!detailsExtended && (
              <>
                {'Learn more about Time Management Benefits '}
                <Typography
                  component='span'
                  sx={{
                    color: 'black',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={() => setDetailsExtended(true)}
                >
                  here
                </Typography>
                .
              </>
            )}
          </PTOHour>
        )}
      </Box>

      <Box
        sx={{
          position: 'fixed',
          top: xs ? 54 : 64,
          right: detailsExtended ? 0 : '-31rem',
          maxHeight: `calc(100% - ${xs ? 54 : 64}px)`,
          maxWidth: '30rem',
          overflowY: 'scroll',
          borderLeft: '1px solid lightgray',
          paddingLeft: '1rem',
          backgroundColor: 'white',
          transition: 'right 0.5s ease-in-out',
          zIndex: 5,
        }}
      >
        <TimeManagementsBenefitsText />
      </Box>

      <IconButton
        onClick={() => setDetailsExtended((prev) => !prev)}
        aria-label={detailsExtended ? 'Open benefits tab' : 'Close benefits tab'}
        sx={{
          position: 'fixed',
          top: '50%',
          right: detailsExtended ? '31.5rem' : '.5rem',
          transform: 'translateY(-50%)',
          transition: 'right 0.5s ease-in-out',
          zIndex: 10,
          backgroundColor: 'white',
          border: `1px solid lightgrey`,
          '&:hover': {
            backgroundColor: '#f0f0f0',
          },
          [theme.breakpoints.down('sm')]: {
            top: 'auto',
            bottom: 30,
            transform: 'translateY(0)',
            right: '1rem',
          },
        }}
      >
        {detailsExtended ? <ArrowForward /> : <ArrowBack />}
      </IconButton>
    </>
  );
};

export default RequestsPage;
