import { gql } from '@apollo/client';

export const REMOVE_DATE_EVENT = gql`
  mutation removeDateEvent($id: Int!) {
    removeDateEvent(id: $id) {
      user {
        username
        email
      }
      eventType {
        name
      }
    }
  }
`;
