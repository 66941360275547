import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { acceptablePathnames } from '../routes';
import BuildBreadcrumbs from '../components/buildBreadcrumbs/buildBreadcrumbs';
import { useCurrentUser } from '../providers/CurrentUserContextProvider';
import { adminPathnames } from '../routes';

export default function BasicBreadcrumbs() {
  const { isLoading: isAuthLoading, isAuthenticated } = useAuth0();
  const { currentUserData, isAdmin } = useCurrentUser();

  const location = useLocation();
  const breadcrumbArr = ['/', '/dashboard', '/login', '/unauthorized', '/500'];
  const isLoggedIn = !isAuthLoading && isAuthenticated && currentUserData;
  const isPathnameValid = acceptablePathnames.includes(location.pathname);
  const isCurrentRouteAdmin = adminPathnames.includes(location.pathname);

  const crumbs = location.pathname.split('/');

  const isBreadcrumbsRendered = !isCurrentRouteAdmin
    ? !breadcrumbArr.includes(location.pathname) && isLoggedIn && isPathnameValid
    : isAdmin;

  return (
    <>
      <BuildBreadcrumbs path={crumbs} isBreadcrumbsRendered={Boolean(isBreadcrumbsRendered)} />
    </>
  );
}
