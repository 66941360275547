import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import ReusableButton from '../../../components/buttons/ReusableButton';
import { useCurrentUser } from '../../../providers/CurrentUserContextProvider';
import formatDate from '../../../utils/FormatDate';

const Container = styled(Box)`
  padding: 1rem;
`;
const ProfileContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 0.25rem;
`;

const ProfileData = styled(Typography)`
  display: flex;
  justify-content: left;
  font-size: 1rem;
  ${({ theme }) => `
    font-family: ${theme.typography.fontFamily};
  `}
`;

const Profile = () => {
  const { currentUserData } = useCurrentUser();
  const formattedDate = formatDate(currentUserData?.userByEmail?.startDate);

  return (
    <>
      {currentUserData && (
        <>
          <Container
            sx={{
              '@media (min-width: 20rem)': {
                width: '17.5rem',
              },
              '@media (min-width: 25rem)': {
                width: '19rem',
              },
              '@media (min-width: 34.375rem)': {
                width: '21rem',
              },
              '@media (min-width: 56.25rem)': {
                width: '23rem',
              },
              boxShadow: '1rem 1rem 2rem #00000033',
              borderRadius: '0.5rem',
              marginTop: '1rem',
              marginBottom: '2rem',
              backgroundColor: 'white',
            }}
          >
            <ProfileContainer>
              <ProfileData sx={{ fontWeight: 'bold', marginRight: '0.5rem' }}>
                {' '}
                Email:{' '}
                <span style={{ fontWeight: 'normal', marginLeft: '0.3rem' }}>
                  {currentUserData?.userByEmail?.email}
                </span>
              </ProfileData>
            </ProfileContainer>
            <ProfileContainer>
              <ProfileData sx={{ fontWeight: 'bold', marginRight: '0.5rem' }}>
                Start Date:{' '}
                <span style={{ fontWeight: 'normal', marginLeft: '0.3rem' }}>{formattedDate}</span>
              </ProfileData>
            </ProfileContainer>
            <ProfileContainer style={{ marginBottom: '1rem' }}>
              <ProfileData sx={{ fontWeight: 'bold', width: '100%' }}>
                <span style={{ whiteSpace: 'nowrap' }}>Display Name:</span>

                <span
                  style={{
                    fontWeight: 'normal',
                    marginLeft: '0.3rem',
                    display: 'inline-block',
                    maxWidth: '100%',
                    overflow: 'auto',
                    textWrap: 'nowrap',
                  }}
                >
                  {currentUserData?.userByEmail?.displayName}
                </span>
              </ProfileData>
            </ProfileContainer>
            <ReusableButton
              aria-label='Edit profile'
              href='/profile/edit'
              style={{ width: '10rem', marginLeft: '0.3rem' }}
            >
              Edit Profile
            </ReusableButton>
          </Container>
        </>
      )}
    </>
  );
};

export default Profile;
