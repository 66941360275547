import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import ReusableButton from '../buttons/ReusableButton';
import BaseModal from './BaseModal';

interface ModalProps {
  open: boolean;
  onClose: () => void;
  onReturnDashboard: () => void;
  editPage: boolean;
}

// form submission modal updated in size due to creation of base modal
const FormSubmissionModal: FC<ModalProps> = ({ open, onClose, onReturnDashboard, editPage }) => {
  const content = (
    <Box sx={{ paddingLeft: 1 }}>
      <Typography sx={{ textAlign: 'left', fontSize: '1.15rem' }}>
        Your request has been successfully {editPage ? 'edited.' : 'submitted.'}
      </Typography>
    </Box>
  );

  const actions = (
    <>
      <ReusableButton
        aria-label='Submit another request'
        onClick={onClose}
        style={{ width: '15.5rem' }}
      >
        Submit Another Request
      </ReusableButton>
      <ReusableButton
        aria-label='Return to Dashboard'
        onClick={onReturnDashboard}
        style={{ width: '15.5rem', marginLeft: 0 }}
      >
        Return to Dashboard
      </ReusableButton>
    </>
  );

  return <BaseModal open={open} title='THANK YOU!' content={content} actions={actions} />;
};

export default FormSubmissionModal;
