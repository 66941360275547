import { gql } from '@apollo/client';

export const GET_ALL_PENDING_DATE_EVENTS = gql`
  query DateEvents {
    dateEvents(status: Pending) {
      user {
        username
      }
      eventType {
        code
      }
      start
      hours
      id
    }
  }
`;
