import { Box, TextField, InputLabel } from '@mui/material';
import { useMutation } from '@apollo/client';
import { UPDATE_USER } from '../../../graphql/users/mutations/updateUser';
import ReusableButton from '../../../components/buttons/ReusableButton';
import { useState, useEffect } from 'react';
import { useCurrentUser } from '../../../providers/CurrentUserContextProvider';

const EditProfile = () => {
  const { currentUserData } = useCurrentUser();

  const [displayName, setDisplayName] = useState<string>('');
  const [updateUser] = useMutation(UPDATE_USER);

  useEffect(() => {
    if (currentUserData?.userByEmail?.displayName) {
      setDisplayName(currentUserData.userByEmail.displayName);
    }
  }, [currentUserData]);

  const handleDisplayNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayName(event.target.value);
  };

  const handleSubmit = async () => {
    updateUser({
      variables: {
        input: {
          id: currentUserData?.userByEmail?.id,
          displayName,
        },
      },
    });
  };

  return (
    <Box
      component='form'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        '@media (min-width: 20rem)': {
          width: '17.5rem',
        },
        '@media (min-width: 25rem)': {
          width: '19rem',
        },
        '@media (min-width: 34.375rem)': {
          width: '21rem',
        },
        '@media (min-width: 56.25rem)': {
          width: '23rem',
        },
        boxShadow: '1rem 1rem 2rem #00000033',
        borderRadius: '0.5rem',
        marginTop: '1rem',
        marginBottom: '2rem',
        padding: '1rem',
        backgroundColor: 'white',
      }}
    >
      <InputLabel
        htmlFor='email-input'
        sx={{ fontWeight: 'bold', color: 'black', paddingLeft: '0.5rem' }}
      >
        Email
      </InputLabel>
      <TextField
        id='email-input'
        fullWidth
        variant='outlined'
        value={currentUserData?.userByEmail?.email ?? ''}
        InputProps={{
          readOnly: true,
        }}
        sx={{ mb: 2, backgroundColor: 'white', width: '95%', paddingLeft: '0.5rem' }}
      />

      <InputLabel
        htmlFor='display-name-input'
        sx={{ fontWeight: 'bold', color: 'black', paddingLeft: '0.5rem' }}
      >
        Display Name
      </InputLabel>
      <TextField
        id='display-name-input'
        fullWidth
        variant='outlined'
        value={displayName}
        onChange={handleDisplayNameChange}
        sx={{ mb: 2, backgroundColor: 'white', width: '95%', paddingLeft: '0.5rem' }}
      />

      <ReusableButton
        aria-label='Update profile'
        variant='outlined'
        href='/profile'
        onClick={handleSubmit}
        style={{ width: '10rem', marginLeft: '0.5rem' }}
      >
        Update
      </ReusableButton>
    </Box>
  );
};

export default EditProfile;
