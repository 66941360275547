import { capitalizeFirstLetter } from './capitalizeFirstLetter';

export const capitalizeRequestType = (req: string) => {
  if (req.toLowerCase() === 'pto' || req.toLowerCase() === 'wfh') {
    return req.toUpperCase();
  }

  if (
    req.toLowerCase() === 'exception' ||
    req.toLowerCase() === 'flex' ||
    req.toLowerCase() === 'unpaid'
  ) {
    return capitalizeFirstLetter(req);
  }
};
