import { useQuery } from '@apollo/client';
import { GET_ALL_USERS } from '../../graphql/users/queries/getAllUsers';
import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';

interface User {
  id: number;
  username: string;
}

interface Props {
  selectedUserId: number | string | undefined;
  handleSelectUser: (userId: number) => void;
  disabled?: boolean;
}

const UsersDropdown = ({ selectedUserId, handleSelectUser, disabled }: Props) => {
  const { data } = useQuery(GET_ALL_USERS);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const handleChange = (event: React.SyntheticEvent, value: User | null) => {
    setSelectedUser(value);
    if (value) {
      handleSelectUser(value.id);
    }
  };

  useEffect(() => {
    if (data?.users.length) {
      setSelectedUser(data.users.find((u: User) => u.id === selectedUserId));
    }
  }, [data, selectedUserId, setSelectedUser]);

  return (
    <Autocomplete
      id='owner'
      value={selectedUser}
      options={data?.users ?? []}
      renderInput={(params) => <TextField {...params} />}
      onChange={handleChange}
      getOptionLabel={(option: User) => option.username}
      disabled={disabled}
    />
  );
};

export default UsersDropdown;
