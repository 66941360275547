import { SxProps, Typography } from '@mui/material';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  sx?: SxProps;
}

const Header = ({ children, sx }: Props) => {
  return (
    <Typography sx={{ ...{ fontSize: '1.5rem', fontWeight: 'bold', marginTop: '1rem' }, ...sx }}>
      {children}
    </Typography>
  );
};

export default Header;
