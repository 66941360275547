import { gql } from '@apollo/client';

export const GET_ALL_USERS = gql`
  query getAllUsers($isPtoHoursUsed: Boolean = false) {
    users(isPtoHoursUsed: $isPtoHoursUsed) {
      wfhDays
      exceptionDays
      username
      startDate
      role
      ptoRate
      ptoHours
      id
      flexDays
      emailSubscriptions
      email
      auth0Id
      displayName
      unpaidHours
      ptoHoursUsed @include(if: $isPtoHoursUsed)
      percentRemaining @include(if: $isPtoHoursUsed)
    }
  }
`;
