import { Alert, Box, ClickAwayListener, Stack, Table, useMediaQuery } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styled, { useTheme } from 'styled-components';
import { useQuery } from '@apollo/client';
import { GET_ALL_PENDING_DATE_EVENTS } from '../../../graphql/dateEvents/queries/getAllPendingDateEvents';
import ReusableButton from '../../../components/buttons/ReusableButton';
import formatDate from '../../../utils/FormatDate';
import { capitalizeRequestType } from '../../../utils/capitalizeRequestType';
import { RequestStatus } from '../../../enums/RequestStatus';
import Header from '../../../components/text/Header';
import { useNavigate } from 'react-router-dom';
import { useCurrentUser } from '../../../providers/CurrentUserContextProvider';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Spinner from '../../../components/Spinner';
import { useDeleteRequest } from '../../../providers/DeleteRequestContextProvider';
import { formatNumberWithDecimal } from '../../../helpers/formattingAccruedBalance';
import { SortByInterface } from '../../../helpers/sorting';
import SortArrow from '../../../components/Tables/SortArrow';
import { handleSort } from '../../../helpers/handleSort';

interface User {
  username: string;
}

interface EventType {
  code: string;
}

export interface Event {
  id: number;
  user: User;
  eventType: EventType;
  start: string;
  hours: number;
  status?: RequestStatus;
}

interface StyledTableHeaderProps extends TableCellProps {
  textAlign?: 'left' | 'center' | 'right'; // Add other textAlign values if needed
  selected?: boolean;
  sortConfig?: SortByInterface;
}

export const StyledTableHeader = styled(TableCell)<StyledTableHeaderProps>(
  ({ theme, textAlign = 'left', selected, sortConfig, onClick }) => ({
    padding: theme.spacing(0.75),
    backgroundColor: 'white',
    fontWeight: 700,
    lineHeight: '1.5rem',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '1rem',
    cursor: onClick ? 'pointer' : 'default',
    textAlign,
    position: 'sticky',
    top: 0,
    userSelect: 'none',
    '.sortArrow': {
      visibility: selected ? 'visible' : 'hidden',
    },
    '&:hover .sortArrow': {
      visibility: 'visible',
      transform: !selected && sortConfig?.direction === 'DESC' ? 'scaleY(-1)' : '',
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0.1),
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0.75),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1),
    },
  })
);

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(0.5),
  fontSize: '1rem',
  lineHeight: '1.5rem',
  textAlign: 'left',
  [theme.breakpoints.down('xs')]: {
    padding: theme.spacing(0.1),
  },
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(0.75),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(1),
  },
}));

const PendingEvents = () => {
  const { data: dateEvent, loading } = useQuery(GET_ALL_PENDING_DATE_EVENTS);
  const [sortConfig, setSortConfig] = useState<SortByInterface>({ column: null, direction: 'ASC' });
  const { column, direction } = sortConfig;
  const theme = useTheme();
  const { caxyLightGray } = theme.colors;
  const sm = useMediaQuery(theme.breakpoints.down('600'));
  const navigate = useNavigate();
  const { currentUserData } = useCurrentUser();

  const {
    isDeleted,
    setIsDeleted,
    userDeleteMessage,
    setUserDeleteMessage,
    requestedUserEmail,
    setRequestedUserEmail,
    yourDeleteMessage,
    setYourDeleteMessage,
    setIsBulkDeleted,
  } = useDeleteRequest();

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isDeleted) {
      timer = setTimeout(() => {
        setIsDeleted(false);
        setIsBulkDeleted(false);
        setUserDeleteMessage('');
        setYourDeleteMessage('');
        setRequestedUserEmail('');
      }, 2500);
    }
    return () => clearTimeout(timer);
  }, [
    isDeleted,
    setIsDeleted,
    setUserDeleteMessage,
    setYourDeleteMessage,
    setRequestedUserEmail,
    setIsBulkDeleted,
  ]);

  const sortedEvents = useMemo(() => {
    if (!dateEvent?.dateEvents || column === null) return dateEvent?.dateEvents || [];

    return [...dateEvent.dateEvents].sort((a, b) => {
      const isReversed = direction === 'DESC' ? 1 : -1;

      // Sort by date in ascending order as secondary sort.
      const secondarySort = new Date(a.start).getTime() - new Date(b.start).getTime();

      switch (column) {
        case 'username':
          return isReversed * a.user.username.localeCompare(b.user.username) || secondarySort;
        case 'eventType':
          return isReversed * a.eventType.code.localeCompare(b.eventType.code) || secondarySort;
        case 'date':
          return isReversed * secondarySort;
        case 'hours':
          return isReversed * (a.hours - b.hours) || secondarySort;
        default:
          return 0;
      }
    });
  }, [dateEvent, column, direction]);

  const onSort = useCallback(
    (columnName: string) => {
      const newDirection = handleSort(columnName, sortConfig);
      setSortConfig({ column: columnName, direction: newDirection });
    },
    [sortConfig, setSortConfig]
  );

  if (loading) return <Spinner />;

  const isCurrentUserTheOneWhoRequestedTheDateEvent =
    currentUserData?.userByEmail.email === requestedUserEmail;

  return (
    <>
      {isDeleted && (
        <ClickAwayListener
          onClickAway={() => {
            setIsDeleted(false);
            setIsBulkDeleted(false);
            setUserDeleteMessage('');
            setYourDeleteMessage('');
            setRequestedUserEmail('');
          }}
        >
          <Alert
            sx={{ justifyContent: 'center', width: '17.5rem', margin: '0 auto', marginTop: '1rem' }}
            variant='filled'
            severity='error'
          >
            {isCurrentUserTheOneWhoRequestedTheDateEvent ? yourDeleteMessage : userDeleteMessage}
          </Alert>
        </ClickAwayListener>
      )}
      <Box
        sx={{
          width: dateEvent?.dateEvents.length ? { md: '40rem' } : '19.6rem',
          display: 'flex',
          flexDirection: 'column',
          marginX: '1rem',
          boxShadow: dateEvent?.dateEvents.length ? '1rem 1rem 2rem #00000033' : '',
          marginTop: '2rem',
          marginBottom: '2rem',
          borderRadius: '0.3rem',
          paddingTop: '1rem',
          paddingX: '1rem',
          backgroundColor: dateEvent?.dateEvents.length ? 'white' : caxyLightGray,
        }}
      >
        {dateEvent?.dateEvents.length ? (
          <>
            <Box sx={{ position: 'sticky', top: 0, background: 'white', zIndex: 1 }}>
              <Header
                sx={{
                  marginTop: 0,
                  marginBottom: '1.5rem',
                  marginLeft: '0.5rem',
                  borderBottom: '2px solid white',
                }}
              >
                Pending Events
              </Header>
            </Box>
            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <StyledTableHeader
                      selected={column === 'username'}
                      sx={{
                        width: '8rem',
                      }}
                      onClick={() => onSort('username')}
                      sortConfig={sortConfig}
                    >
                      <Stack direction='row'>
                        Owner
                        <SortArrow sortConfig={sortConfig} />
                      </Stack>
                    </StyledTableHeader>
                    <StyledTableHeader
                      selected={column === 'eventType'}
                      sx={{
                        width: sm ? '5rem' : '6rem',
                      }}
                      onClick={() => onSort('eventType')}
                      sortConfig={sortConfig}
                    >
                      <Stack direction='row'>
                        Type
                        <SortArrow sortConfig={sortConfig} />
                      </Stack>
                    </StyledTableHeader>
                    <StyledTableHeader
                      selected={column === 'date'}
                      sx={{
                        width: '9rem',
                      }}
                      onClick={() => onSort('date')}
                      sortConfig={sortConfig}
                    >
                      <Stack direction='row'>
                        Date
                        <SortArrow sortConfig={sortConfig} />
                      </Stack>
                    </StyledTableHeader>
                    <StyledTableHeader
                      selected={column === 'hours'}
                      sx={{
                        width: sm ? '7rem' : '6rem',
                      }}
                      onClick={() => onSort('hours')}
                      sortConfig={sortConfig}
                    >
                      <Stack direction='row'>
                        Hours
                        <SortArrow sortConfig={sortConfig} />
                      </Stack>
                    </StyledTableHeader>
                    {!sm && (
                      <StyledTableHeader
                        sx={{
                          width: '5rem',
                        }}
                      >
                        Action
                      </StyledTableHeader>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedEvents.map((event: Event, index: number) => (
                    <TableRow
                      key={`${event.start}-${index}`}
                      onClick={() => sm && navigate(`/requests/view/${event.id}`)}
                    >
                      <StyledTableCell
                        sx={{
                          maxWidth: '8rem',
                          overflowX: 'scroll',
                          '&::-webkit-scrollbar': {
                            display: 'none',
                          },
                          scrollbarWidth: 'none',
                        }}
                      >
                        {event?.user.username}
                      </StyledTableCell>
                      <StyledTableCell>
                        {capitalizeRequestType(event?.eventType.code)}
                      </StyledTableCell>

                      <StyledTableCell>{formatDate(event.start)}</StyledTableCell>
                      <StyledTableCell>{formatNumberWithDecimal(event.hours)}</StyledTableCell>
                      {!sm && (
                        <StyledTableCell>
                          <ReusableButton
                            size='small'
                            style={{ width: '5rem' }}
                            href={`/requests/view/${event.id}`}
                          >
                            View
                          </ReusableButton>
                        </StyledTableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <Box sx={{ width: '18.5rem', marginTop: '-2rem' }}>
            <span style={{ textAlign: 'left' }}>There are no pending events.</span>
          </Box>
        )}
      </Box>
    </>
  );
};

export default PendingEvents;
