import { Box, BoxProps } from '@mui/material';
import { LinkTag } from '../buildBreadcrumbs/buildBreadcrumbs';
import { useTheme } from '@mui/material';
import { ResponsiveStyleValue } from '@mui/system';

interface MenuItemProps extends BoxProps {
  url?: string;
  linkText?: string;
  width?: ResponsiveStyleValue<string | number> | undefined;
}

export const MenuItem = ({ url, linkText, children, width, ...rest }: MenuItemProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        fontFamily: theme.typography.fontFamily,
        width: width ?? '10rem',
        display: 'flex',
        alignItems: 'center',
        padding: rest?.padding ?? '.38rem 1rem',
        minHeight: '1rem',
        color: 'inherit',
        cursor: 'pointer',
        textDecoration: 'none',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
        '&:focus': {
          backgroundColor: 'rgba(0, 0, 0, 0.12)',
        },
      }}
      {...rest}
    >
      {url && linkText && (
        <LinkTag underlineOnHover={false} to={url}>
          {linkText}
        </LinkTag>
      )}
      {children}
    </Box>
  );
};
