import { useAuth0 } from '@auth0/auth0-react';
import Spinner from '../Spinner';
import { ProtectedRouteProps } from './ProtectedRoute';
import { Role } from '../../enums/role';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import BaseErrorPage from '../../routes/ErrorPages/BaseErrorPage';
import { useCurrentUser } from '../../providers/CurrentUserContextProvider';

const ProtectedAdminRoute = ({ children }: ProtectedRouteProps) => {
  const {
    isLoading: isAuthLoading,
    isAuthenticated,
    user: authUser,
    loginWithRedirect,
  } = useAuth0();

  const location = useLocation();
  const navigate = useNavigate();

  const { currentUserData, isUserLoading } = useCurrentUser();

  useEffect(() => {
    if (!isUserLoading && !isAuthLoading) {
      // Set the userRole incase the default value is undefined
      const notLoggedIn = !authUser && !currentUserData && !isAuthenticated;

      if (notLoggedIn) {
        localStorage.setItem('wtf.redirect_url', location.pathname);
        loginWithRedirect();
      }
    }
  }, [
    authUser,
    currentUserData,
    isAuthLoading,
    isAuthenticated,
    isUserLoading,
    location,
    location.pathname,
    loginWithRedirect,
    navigate,
  ]);

  if (isAuthLoading || isUserLoading) return <Spinner />;

  if (currentUserData) {
    const role = currentUserData.userByEmail.role;
    const loggedInNotAdmin = isAuthenticated && role !== Role.ADMIN;

    if (loggedInNotAdmin) {
      return <BaseErrorPage error={403} message={"You're toad-ally not allowed here!"} />;
    }

    return <>{children}</>;
  }

  return <></>;
};

export default ProtectedAdminRoute;
