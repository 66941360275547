import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const Root = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    isAuthenticated ? navigate('dashboard') : navigate('login');
  }, [isAuthenticated, navigate]);

  return <></>;
};

export default Root;
