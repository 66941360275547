import { gql } from '@apollo/client';

export const GET_ALL_DATE_EVENTS_BY_ID = gql`
  query dateEventByUserId($userId: Float!) {
    dateEventByUserId(userId: $userId) {
      userId
      eventType {
        code
      }
      start
      hours
      status
      id
    }
  }
`;
