import { gql } from '@apollo/client';

export const GET_EVENT_TYPE_BY_CODE = gql`
  query EventType($code: String!) {
    eventType(code: $code) {
      code
      id
      name
    }
  }
`;
