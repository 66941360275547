import { DateTime } from 'luxon';

const formatDate = (startDate: string | Date | undefined) => {
  const isoDate = startDate;
  let formattedDate = '';

  if (isoDate) {
    if (typeof isoDate === 'string') {
      const date = DateTime.fromISO(isoDate);
      formattedDate = date.toLocaleString(DateTime.DATE_MED);
    } else if (isoDate instanceof Date) {
      const date = DateTime.fromJSDate(isoDate);
      formattedDate = date.toLocaleString(DateTime.DATE_MED);
    }
  }
  return formattedDate;
};

export default formatDate;
