import { Oval } from 'react-loader-spinner';
import { Box } from '@mui/material';

const Spinner = () => {
  return (
    <Box
      sx={{
        height: '75vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Oval
        visible={true}
        height='120'
        width='120'
        color='#4fa94d'
        ariaLabel='oval-loading'
        wrapperStyle={{}}
        wrapperClass=''
      />
    </Box>
  );
};

export default Spinner;
