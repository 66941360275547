import { Box, Typography } from '@mui/material';
import frogIcon from '../../assets/images/frog-pixel-small.png';
import ReusableButton from '../../components/buttons/ReusableButton';
import { useAuth0 } from '@auth0/auth0-react';

interface ErrorPageProps {
  error: number | string;
  message: string;
}

const BaseErrorPage = ({ error, message }: ErrorPageProps) => {
  const { isAuthenticated } = useAuth0();

  const isErrorString = typeof error === 'string';

  const getErrorText = () => {
    if (isErrorString) {
      return { xs: '6rem', sm: '7rem' };
    } else {
      return { xs: '8rem', sm: '9rem' };
    }
  };

  return (
    <>
      <Box
        sx={{
          display: { xs: 'block', sm: 'flex' },
          alignItems: 'center',
          margin: 'auto',
          justifyContent: 'center',
          width: { xs: '100vw', sm: '37.5rem' },
          marginTop: { sm: '5rem' },
        }}
      >
        <Box sx={{ width: { xs: '100%', sm: '50%' }, display: 'flex', justifyContent: 'center' }}>
          <img alt='frog icon' src={frogIcon}></img>
        </Box>
        <Box
          sx={{
            width: { xs: isErrorString ? '15rem' : '100%', sm: '50%' },
            margin: 'auto',

            textAlign: 'left',
            paddingLeft: { xs: 0, sm: '1rem' },
            paddingRight: { xs: 0, sm: '0.9rem' },
            display: 'flex',
            flexDirection: 'column',
            gap: '2rem',
            alignItems: 'center',
          }}
        >
          <Typography sx={{ fontSize: getErrorText() }}>{error}</Typography>
          <Typography sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem' }, pl: 'rem' }}>
            {message}
          </Typography>
          <ReusableButton
            aria-label='Navigate to homepage'
            href={isAuthenticated ? '/dashboard' : '/login'}
          >
            Hop Back Home
          </ReusableButton>
        </Box>
      </Box>
    </>
  );
};

export default BaseErrorPage;
