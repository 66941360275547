import { gql } from '@apollo/client';

export const APPROVE_OR_DENY_SINGLE_DATE_EVENT = gql`
  mutation approveOrDenySingleRequest($input: ApproveOrDenySingleRequestInput!) {
    approveOrDenySingleRequest(approveOrDenySingleRequestInput: $input) {
      id
      status
      start
      compensationType
      end
      statusReason
      user {
        id
        username
        ptoHours
        wfhDays
        flexDays
        exceptionDays
        role
        unpaidHours
      }
      eventType {
        name
        code
      }
    }
  }
`;
