import { ReactNode } from 'react';
import BasicBreadcrumbs from './BasicBreadcrumbs';
import Box from '@mui/material/Box';
import NavigationBar from '../components/navigation/NavigationBar';
import { ErrorBoundaryWrapper } from '../routes';
import { useTheme } from 'styled-components';

const Layout = ({ children }: { children: ReactNode }) => {
  const theme = useTheme();
  const { caxyLightGray } = theme.colors;
  return (
    <>
      <Box sx={{ minHeight: '100vh', backgroundColor: caxyLightGray }}>
        <NavigationBar />
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <ErrorBoundaryWrapper>
            <BasicBreadcrumbs />
            {children}
          </ErrorBoundaryWrapper>
        </Box>
      </Box>
    </>
  );
};

export default Layout;
