import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './routes/Layout';
import { ThemeProvider } from '@mui/material';
import theme from './theme';
import { Auth0Provider } from '@auth0/auth0-react';
import './App.css';
import CurrentUserProvider from './providers/CurrentUserContextProvider';
import { isRouteAccessible, routes } from './routes';
import { Toaster } from 'react-hot-toast';
import AuthorizedApolloProvider from './providers/AuthorizedApolloProvider';
import DeleteRequestProvider from './providers/DeleteRequestContextProvider';

const domain = process.env.REACT_APP_AUTH0_DOMAIN || '';
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || '';

function App() {
  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      cacheLocation='localstorage'
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
      authorizationParams={{
        redirect_uri: `${process.env.REACT_APP_CLIENT_URL}/dashboard`,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: 'openid profile email offline_access',
        max_age: 3600,
      }}
    >
      <AuthorizedApolloProvider>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <CurrentUserProvider>
              <DeleteRequestProvider>
                <Layout>
                  <Toaster position='top-center' />
                  <Routes>
                    {routes.map((route, i) => (
                      <Route
                        key={`${route.path}-${i}`}
                        element={isRouteAccessible(route)}
                        path={route.path}
                      ></Route>
                    ))}
                  </Routes>
                </Layout>
              </DeleteRequestProvider>
            </CurrentUserProvider>
          </BrowserRouter>
        </ThemeProvider>
      </AuthorizedApolloProvider>
    </Auth0Provider>
  );
}

export default App;
