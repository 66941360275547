import Root from './routes/root';
import RequestPTO from './routes/User/Requests/RequestPTO';
import RequestExceptionTime from './routes/User/Requests/RequestExceptionTime';
import AdminUserPage from './routes/Admin/AdminUserPage';
import UserPage from './routes/Admin/UserPage';
import EditProfile from './routes/User/Profile/EditProfile';
import PendingEvents from './routes/User/Events/PendingEvents';
import Profile from './routes/User/Profile/Profile';
import RequestsPage from './routes/User/Requests/Requests';
import EventHistory from './routes/User/Events/EventHistory';
import RequestWFH from './routes/User/Requests/RequestWFH';
import RequestFlex from './routes/User/Requests/RequestFlex';
import Dashboard from './routes/Dashboard';
import Login from './routes/Login';
import { ReactElement, ReactNode } from 'react';
import ProtectedRoute from './components/auth/ProtectedRoute';
import ProtectedAdminRoute from './components/auth/ProtectedAdminRoute';
import BaseErrorPage from './routes/ErrorPages/BaseErrorPage';
import EditRequest from './routes/User/Requests/EditRequest';
import UserRequest from './routes/Admin/UserRequest';
import PtoSpend from './routes/Reports/PtoSpend';
import Error500Page from './routes/ErrorPages/Error500Page';
import TotalBenefits from './routes/Reports/TotalBenefits';
import RequestUnpaid from './routes/User/Requests/RequestUnpaid';
import ErrorBoundary from './components/ErrorBoundary';

interface RouteType {
  path: string;
  element: ReactElement;
  protected: boolean;
  isAdmin: boolean;
}

export const routes: RouteType[] = [
  {
    path: '/',
    element: <Root />,
    protected: true,
    isAdmin: false,
  },
  {
    path: '/requests/pto',
    element: <RequestPTO />,
    protected: true,
    isAdmin: false,
  },
  {
    path: '/requests/unpaid',
    element: <RequestUnpaid />,
    protected: true,
    isAdmin: false,
  },
  {
    path: '/requests/exception',
    element: <RequestExceptionTime />,
    protected: true,
    isAdmin: false,
  },
  {
    path: '/requests/wfh',
    element: <RequestWFH />,
    protected: true,
    isAdmin: false,
  },
  {
    path: '/requests/flex',
    element: <RequestFlex />,
    protected: true,
    isAdmin: false,
  },
  {
    path: '/requests/pending',
    element: <PendingEvents />,
    protected: true,
    isAdmin: true,
  },
  {
    path: '/requests/edit/:id',
    element: <EditRequest />,
    protected: true,
    isAdmin: false,
  },
  {
    path: '/dashboard',
    element: <Dashboard />,
    protected: true,
    isAdmin: false,
  },
  {
    path: '/requests',
    element: <RequestsPage />,
    protected: true,
    isAdmin: false,
  },
  {
    path: '/event-history',
    element: <EventHistory />,
    protected: true,
    isAdmin: false,
  },
  {
    path: '/login',
    element: <Login />,
    protected: false,
    isAdmin: false,
  },
  {
    path: '/profile',
    element: <Profile />,
    protected: true,
    isAdmin: false,
  },
  {
    path: '/users',
    element: <AdminUserPage />,
    protected: true,
    isAdmin: true,
  },
  {
    path: '/users/:id/view',
    element: <UserPage />,
    protected: true,
    isAdmin: true,
  },
  {
    path: '/profile/edit',
    element: <EditProfile />,
    protected: true,
    isAdmin: false,
  },
  {
    path: '/requests/view/:id',
    element: <UserRequest />,
    protected: true,
    isAdmin: false,
  },
  {
    path: '/reports/pto-spend',
    element: <PtoSpend />,
    protected: true,
    isAdmin: true,
  },
  {
    path: '/reports/total-benefits',
    element: <TotalBenefits />,
    protected: true,
    isAdmin: true,
  },

  {
    path: '*',
    element: <BaseErrorPage error={404} message='Oops! Not clear how to leap to that page' />,
    protected: false,
    isAdmin: false,
  },
  {
    path: '/500',
    element: <Error500Page />,
    protected: false,
    isAdmin: false,
  },
];

export const acceptablePathnames = routes.map((route) => route.path);
export const adminPathnames = routes.filter((route) => route.isAdmin).map((rt) => rt.path);

export const ErrorBoundaryWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <ErrorBoundary
      fallback={
        <BaseErrorPage
          error='Error'
          message='Something went wrong! Please head back to the dashboard.'
        />
      }
    >
      {children}
    </ErrorBoundary>
  );
};

export const isRouteAccessible = (route: RouteType) => {
  if (!route.protected && !route.isAdmin) {
    return route.element;
  }
  if (route.protected && !route.isAdmin) {
    return <ProtectedRoute>{route.element}</ProtectedRoute>;
  }

  if (route.protected && route.isAdmin) {
    return <ProtectedAdminRoute> {route.element}</ProtectedAdminRoute>;
  }
};
