import { gql } from '@apollo/client';

export const UPDATE_DATE_EVENT = gql`
  mutation updateDateEvent($input: UpdateDateEventInput!) {
    updateDateEvent(updateDateEventInput: $input) {
      compensationType
      end
      userId
      eventTypeId
      eventType {
        name
        code
      }
      id
      reasonRequested
      start
      status
      statusReason
      hours
      user {
        id
        username
        ptoHours
        wfhDays
        flexDays
        exceptionDays
        role
        unpaidHours
        email
      }
    }
  }
`;
