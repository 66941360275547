import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import ReusableFormComponent from '../../../components/ReusableForm/ReusableFormComponent';
import { useState } from 'react';

const RequestUnpaid = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const page = pathname.split('/').pop();

  const [key, setKey] = useState<number>(0);

  const resetState = () => {
    setKey(key + 1);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2rem',
      }}
    >
      <ReusableFormComponent key={key} currentPage={page || ''} resetState={resetState} />
    </Box>
  );
};

export default RequestUnpaid;
